import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../uikit/components/atoms/button/button';
import Label from '../../../../uikit/components/atoms/label/label';
import { Select } from '../../../../uikit/components/atoms/select/select';
import { ReactComponent as PlusIcon } from '../../../../uikit/internal/svg/gzm0367-plus.svg';
import { ReactComponent as NavigateRightIcon } from '../../../../uikit/internal/svg/gzm0426-navigate-right.svg';
import { ReactComponent as NavigateLeftIcon } from '../../../../uikit/internal/svg/gzm0428-navigate-left.svg';
import './calendar-toolbar.scss';

export class CalendarToolbar extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
    }


    render() {
        const { title, onDateRangeUpdate, onViewChange } = this.props;
        return (
            <div className="calendar-toolbar">
                <Label variant="headline4" className="calendar-toolbar__calendar-title">
                    {title}
                </Label>

                <div className="calendar-toolbar__action-buttons">
                    {/* <Button variant="subtle" onClick={() => onDateRangeUpdate('today')}>Today</Button> */}

                    <Link to="/calendar/event/create" className="calendar-home-page__upload-button">
                        <Button variant="subtle"><PlusIcon /></Button>
                    </Link>
                    <Button variant="subtle" onClick={() => onDateRangeUpdate('prev')}><NavigateLeftIcon /></Button>
                    <Button variant="subtle" onClick={() => onDateRangeUpdate('next')}><NavigateRightIcon /></Button>


                </div>
                <Select className="calendar-toolbar__select" onChange={(evt) => onViewChange(evt.target.value)}>
                    <option value="month">
                        Month
                    </option>
                    <option value="agendaWeek">
                        Week
                    </option>
                    <option value="agendaThreeDay">
                        3 Day
                    </option>
                    <option value="agendaDay">
                        Day
                    </option>
                </Select>
            </div >
        )
    }
}

export default CalendarToolbar
