import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { childrenType } from '../../../internal/childrenPropType';
import './label.scss';

export default class Label extends PureComponent {
    static propTypes = {
        children: childrenType,
        variant: PropTypes.oneOf([
            'headline1',
            'headline2',
            'headline3',
            'headline4',
            'headline5',
            'headline6',
            'subtitle1',
            'subtitle2',
            'body1',
            'body2',
            'caption',
        ]),
        className: PropTypes.string,
        component: PropTypes.string,
    }

    static defaultProps = {
        variant: 'body1',
        className: null,
        component: null,
    }

    render() {
        const {
            children,
            className: classNameProp,
            component: componentProp,
            variant,
        } = this.props;

        const componentMapping = {
            'headline1': 'h1',
            'headline2': 'h2',
            'headline3': 'h3',
            'headline4': 'h4',
            'headline5': 'h5',
            'headline6': 'h6',
            'subtitle1': 'p',
            'subtitle2': 'p',
            'body1': 'p',
            'body2': 'p',
            'caption': 'p',
        };

        const Component = componentProp || componentMapping[variant] || 'span';
        const classNames = classnames('ui-label', variant, classNameProp);

        return <Component className={classNames}>{children}</Component>
    }
}
