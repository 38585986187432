import gql from 'graphql-tag';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import withRouter from 'react-router/withRouter';
import { compose } from 'recompose';
import { Creators } from '../../../../state/actionTypes';
import Button from '../../../../uikit/components/atoms/button/button';
import Label from '../../../../uikit/components/atoms/label/label';
import { TextField } from '../../../../uikit/components/atoms/text-field/text-field';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { initUpload } from '../../../../util/awsS3';
import { readFilePreview } from '../../../../util/imageUtility';
import { Image } from '../../../../uikit/components/atoms/image/image';
import './documents-upload-page.scss';


const acceptedTypes = ".txt, .png, .jpg, .jpeg, .m4a, .mp4, .csv, .docx, .doc, .pages, .xlsx, .pptx, .ppt, .pdf"
const imageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

export class _DocumentsUploadPage extends Component {

    state = {
        title: '',
        file: null,
        filePreview: null,
        uploading: false
    }

    goBack = () => {
        this.props.history.push('/library/documents');
    }

    clearFile = () => {
        this.setState({
            file: null,
            filePreview: null
        })
    }

    handleTitleChange = (evt) => {
        this.setState({
            title: evt.target.value
        })
    }

    onDrop = (accepted, rejected) => {
        if (rejected.length > 0) {
            this.props.alertError('Unaccepted file type')
        }

        if (!accepted || accepted.length === 0) {
            return;
        }

        const file = accepted[0];
        this.setState({
            file: file
        });


        if (imageTypes.includes(file.type)) {
            readFilePreview(file).then((filePreviewString) => {
                this.setState({
                    filePreview: filePreviewString
                });
            });
        }
    }

    doUpload = () => {
        if (this.state.uploading || !this.state.title) {
            return;
        }
        const documentTitle = this.state.title;
        this.setState({ uploading: true })
        this.props.setLoading(true);
        initUpload(this.state.file)
            .then((url) => {
                this.props.associateDocument({
                    variables: {
                        docTitle: documentTitle,
                        cloudUrl: url,
                        size: this.state.file.size,
                    }
                }).then(() => {
                    this.props.setLoading(false);
                    this.goBack();
                })
            }).catch(() => {
                this.props.setLoading(false);
            })
    }


    render() {
        return (
            <PageTemplate>
                <NavigationBar />
                <PageTemplateContent className="documents-upload-page">
                    <Label variant="headline2">Upload a Document</Label>
                    <Dropzone
                        accept={acceptedTypes}
                        className="documents-upload-page__dropzone"
                        onDrop={this.onDrop} disabled={this.state.file != null}
                        disabledClassName="documents-upload-page__dropzone--disabled">
                        <Label secondary={this.state.file != null}>Try droping some files here, or tap to select files to upload.</Label>
                    </Dropzone>
                    {this.state.file != null
                        ? <div className="documents-upload-page__file-info">
                            <Label variant="subtitle1">File Info</Label>
                            <TextField placeholder="File Name" value={this.state.title} onChange={this.handleTitleChange} />
                            <Label>Original Name: {this.state.file.name}</Label>
                        </div>
                        : null
                    }
                    {this.state.filePreview != null
                        ? <React.Fragment>
                            <Label variant="subtitle1">Preview</Label>
                            <Image className="documents-upload-page__image-preview" src={this.state.filePreview} />
                        </React.Fragment>
                        : null
                    }
                    {this.state.file != null
                        ? <div className="documents-upload-page__action-buttons">
                            <Button variant="primary" onClick={this.doUpload}>Upload</Button>
                            <Button variant="subtle" onClick={this.clearFile}>Clear File</Button>
                        </div>
                        : null
                    }
                </PageTemplateContent>

            </PageTemplate>
        )
    }

}

const ASSOCIATE_DOCUMENT = gql`
    mutation AssociateDocument($docTitle: String!, $cloudUrl: String!, $size: Int!) {
        associateDocument(title: $docTitle, cloudUrl: $cloudUrl, size: $size) {
            id
            title
            localUrl
            createdAt
        }
    }
`

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        alertError: (error) => {
            dispatch(Creators.alertError(error));
        },
        setLoading: (loading) => {
            dispatch(Creators.setLoading(loading));
        }
    }
}

export const DocumentsUploadPage = compose(
    withRouter,
    graphql(ASSOCIATE_DOCUMENT, {
        name: 'associateDocument'
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(_DocumentsUploadPage)
