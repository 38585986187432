import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import './modal.scss';

const modalRoot = document.getElementById('modal-root');

export class Modal extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        backdropClick: PropTypes.func,
    }

    state = {
        visible: false,
        backdropClick: () => { }
    }

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        if (this.props.visible) {
            this.show();
        }
    }


    show = () => {
        modalRoot.appendChild(this.el);
        this.setState({ visible: true })
    }

    hide = () => {
        if (modalRoot.contains(this.el)) {
            modalRoot.removeChild(this.el);
        }
        this.setState({ visible: false })
    }

    render() {
        return ReactDOM.createPortal(
            (
                <div className="ui-modal">
                    <div className="ui-modal__backdrop" onClick={this.props.backdropClick} />
                    <div className="ui-modal__container">
                        {this.props.children}
                    </div>
                </div>
            ),
            this.el
        );
    }
};


