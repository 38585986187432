// import { environment } from 'environment';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../uikit/components/atoms/button/button';
import Label from '../../../../uikit/components/atoms/label/label';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import './landing-page.scss';
import image from '../../../../img/stockfamily.jpeg';
import { Card, CardContent } from '../../../../uikit/components/atoms/card/card';
import Divider from '../../../../uikit/components/atoms/divider/divider';


export class LandingPage extends React.PureComponent {

    render() {
        return (
            <PageTemplate>
                <PageTemplateContent className="landing-page">


                    <div className="landing-page__container landing-page__container-left">
                        <div className="landing-page__inner">
                            <Label variant="headline1">Family Moderator</Label>
                            <Label variant="headline2">Co-Parenting made simple.</Label>
                            <Divider />
                            <Label variant="headline3">
                                Messages, Calendars, Documents and Expenses.
                                <br /><br />
                                All in one place, available as a single source of truth.
                            </Label>
                            <Link to="/inbox">
                                <Button variant="primary">Log In / Sign Up</Button>
                            </Link>
                            <Divider />
                            <div className="landing-page__links">
                                <Link to="/privacy-policy">
                                    <Button variant="link">Privacy</Button>
                                </Link>
                                <Label>/</Label>
                                <Link to="/terms-of-service">
                                    <Button variant="link">Terms</Button>
                                </Link>
                            </div>


                        </div>
                    </div>

                    <div className="landing-page__container landing-page__container-right">

                        <img src={image} />

                    </div>
                </PageTemplateContent>
            </PageTemplate >
        );
    }
}

