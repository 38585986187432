import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types'
import moment from 'moment';
import Divider from '../../../../uikit/components/atoms/divider/divider';
import Button from '../../../../uikit/components/atoms/button/button';
import { FormField } from '../../../../uikit/components/molecules/form-field/form-field';
import './calendar-event-form.scss'

const schema = Yup.object().shape({
    title: Yup.string()
        .required('Title is required'),
    startDate: Yup.date()
        .required('Start Date is required'),
    endDate: Yup.date()
        .required('Start Date is required'),
    // startDate: Yup.string()
    //     .required('Start Date is required'),
    // endDate: Yup.string()
    //     .required('Start Date is required'),
    note: Yup.string(),
})

export class CalendarEventForm extends React.Component {

    static propTypes = {
        onComplete: PropTypes.func,
        onCancel: PropTypes.func,
        calendars: PropTypes.array.isRequired,
    }

    render() {
        const { onCancel, onComplete } = this.props;
        return (
            <Formik
                initialValues={{
                    title: '',
                    startDate: moment(),
                    endDate: moment(),
                    note: '',
                    calendar: this.props.calendars[0].id,
                }}
                validationSchema={schema}
                onSubmit={(
                    values,
                    { setSubmitting, setErrors }
                ) => {
                    if (onComplete) {
                        setSubmitting(true);
                        onComplete(values);
                    }
                }}>
                {({ values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="calendar-event-form">
                                <FormField
                                    className="calendar-event-form__primary-input"
                                    variant="text-field"
                                    label="Title"
                                    name="title"
                                    placeholder="Event Title"
                                    value={values.title}
                                    invalid={errors.title && touched.title}
                                    error={errors.title}
                                    onChange={handleChange} />

                                <Divider />
                                <FormField
                                    variant="select"
                                    className="calendar-event-form__calendar-input"
                                    label="Calendar"
                                    name="calendar"
                                    placeholder="Calendar"
                                    value={values.calendar}
                                    invalid={errors.calendar && touched.calendar}
                                    error={errors.calendar}
                                    onChange={handleChange}
                                >
                                    {this.props.calendars.map(c => {
                                        return (
                                            <option value={c.id} key={c.id}>
                                                {c.title}
                                            </option>
                                        )
                                    })}
                                </FormField>


                                <div className="calendar-event-form__dates">

                                    <FormField
                                        outerClassName="calendar-event-form__date-picker-input"
                                        variant="date"
                                        label="Start Date"
                                        name="startDate"
                                        // mask="date"
                                        // type="datetime-local"
                                        placeholder="MM/DD/YYYY HH:MM"
                                        selected={values.startDate}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        invalid={errors.startDate && touched.startDate}
                                        error={errors.startDate}
                                    />
                                    <FormField
                                        outerClassName="calendar-event-form__date-picker-input"
                                        variant="date"
                                        label="End Date"
                                        name="endDate"
                                        // type="datetime-local"
                                        // mask="date"
                                        placeholder="MM/DD/YYYY HH:MM"
                                        selected={values.endDate}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        invalid={errors.endDate && touched.endDate}
                                        error={errors.endDate}
                                    />
                                </div>

                                <FormField
                                    variant="text-field"
                                    textarea
                                    className="calendar-event-form__input"
                                    label="Note"
                                    name="note"
                                    placeholder="Note"
                                    value={values.note}
                                    invalid={errors.note && touched.note}
                                    error={errors.note}
                                    onChange={handleChange} />
                                {/* 


                                */}

                            </div>
                            <div className="calendar-event-form__actions">
                                <Button variant="primary" type="submit">Create</Button>
                                <Button variant="subtle" onClick={onCancel}>Cancel</Button>
                            </div>
                        </form>
                    )}
            </Formik>
        )
    }
}
