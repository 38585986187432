import gql from 'graphql-tag';
import React from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Creators } from '../../../../state/actionTypes';
import { Card, CardContent } from '../../../../uikit/components/atoms/card/card';
import Divider from '../../../../uikit/components/atoms/divider/divider';
import Label from '../../../../uikit/components/atoms/label/label';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { ContainedIcon } from '../../../../uikit/components/atoms/contained-icon/contained-icon';
import { ReactComponent as BellIcon } from '../../../../uikit/internal/svg/gzm0393-bell.svg';
import { ReactComponent as UsersIcon } from '../../../../uikit/internal/svg/gzm0124-users.svg';

import { DashboardNotificationCard } from '../../components/dashboard-notification-card/dashboard-notification-card';
import './dashboard-page.scss';

const _DashboardPage = (props) => {
    const {
        currentUser,
        currentFamily: { currentFamily: cf },
        familyUpdates,
    } = props;

    const { unreadMessages } = familyUpdates;

    const hasNotifications = unreadMessages && unreadMessages.length > 0;
    return (
        <>
            <PageTemplate>
                <NavigationBar />
                <PageTemplateContent className="dashboard-page">
                    {props.currentFamily.loading || familyUpdates.loading
                        ? "Loading" // <LoadingIndicator />
                        : <>
                            <Label variant="headline1">Welcome Back, {currentUser.firstName}</Label>
                            <Label variant="headline2">Here is an overview of your Family</Label>
                            <Divider />

                            <Card className="dashboard-page__section">
                                <CardContent>
                                    <div className="dashboard-page__card-title">
                                        <ContainedIcon>
                                            <UsersIcon />
                                        </ContainedIcon>
                                        <Label variant="subtitle1" className="dashboard-page__section-title">Parents</Label>
                                    </div>
                                    <Divider />
                                    {cf.participants.map(person => {
                                        return (
                                            <Label key={person.id}>
                                                {person.firstName} {person.lastName}
                                            </Label>
                                        )
                                    })}
                                </CardContent>
                            </Card>

                            <Card className="dashboard-page__section">
                                <CardContent>
                                    <div className="dashboard-page__card-title">
                                        <ContainedIcon>
                                            <BellIcon />
                                        </ContainedIcon>
                                        <Label variant="subtitle1" className="dashboard-page__section-title">Notifications</Label>
                                    </div>
                                    <Divider />
                                    {hasNotifications
                                        ? <DashboardNotificationCard feature="messages" type="message" count={unreadMessages.length} />
                                        : (
                                            <Label>
                                                You have no unread notifications!
                                            </Label>
                                        )
                                    }
                                </CardContent>
                            </Card>

                        </>
                    }
                </PageTemplateContent>
            </PageTemplate>

        </>
    )
}
const GET_CURRENT_FAMILY = gql`
    query CurrentFamily { 
        currentFamily {
            id
            title
            participants {
                id
                firstName
                lastName
            }
        }
    }
`;

const GET_FAMILY_UPDATES = gql`
    query FamilyUpdates {
        unreadMessages {
            id
            body
            user {
                id
                firstName
                lastName
            }
            createdAt
        }
    }
`;

const mapStateToProps = state => {
    return {
        currentUser: state.Auth.currentUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openDrawer: () => {
            dispatch(Creators.openDrawer());
        },
    }
}

export const DashboardPage = compose(
    graphql(GET_CURRENT_FAMILY, { name: 'currentFamily' }),
    graphql(GET_FAMILY_UPDATES, { name: 'familyUpdates' }),
    connect(mapStateToProps, mapDispatchToProps)
)(_DashboardPage);
