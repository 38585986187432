import AuthReducer from './AuthReducer';
import UIReducer from './UIReducer';
import { combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducers = {
    UI: UIReducer,
    Auth: AuthReducer,
};

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({ ...reducers });
const pReducer = persistReducer(persistConfig, rootReducer);

export const nonPersistedStore = createStore(rootReducer);
export const store = createStore(pReducer);
export const persistor = persistStore(store);


// const persistedReducer = persistReducer(persistConfig, rootReducer)
// const persistor = persistStore(store);