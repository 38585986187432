import React from 'react'
import PropTypes from 'prop-types'
import './list.scss';
import classnames from 'classnames';

export class List extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
    }

    render() {
        const { children, className: cn } = this.props;
        return (
            <ul className={classnames('ui-list', cn)}>
                {children}
            </ul>
        )
    }
}

export class ListItem extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        // children: childrenType
    }

    render() {
        const { children, className: cn } = this.props;
        return (
            <li className={classnames('ui-list-item', cn)}>
                {children}
            </li>
        )
    }
}


