
import gql from 'graphql-tag';
import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import StripeCheckout from "react-stripe-checkout";
import { CURRENT_USER_QUERY } from '../../../graphql/queries';
import Button from '../../../uikit/components/atoms/button/button';
import { Card, CardContent } from '../../../uikit/components/atoms/card/card';
import Label from '../../../uikit/components/atoms/label/label';
import { NavigationBar } from '../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { PageTemplate, PageTemplateContent } from '../../../uikit/components/molecules/page-template/page-template';
import { CancelSubscription } from '../components/cancel-subscription';
import './subscription-page.scss';
import { Modal } from '../../../uikit/components/molecules/modal/modal';

const CREATE_SUCSCRIPTION_MUTATION = gql`
    mutation CreateSubscriptionMutation($source: String!, $ccLast4: String!) {
        createStripeSubscription(source: $source, ccLast4: $ccLast4) {
            id
            subscriptionStatus
            ccLast4
        }
    }
`;

export class SubscriptionPage extends Component {
    static propTypes = {
    }

    modalRef = React.createRef();

    hideModal = () => {
        this.modalRef.current.hide();
    }

    render() {
        return (
            <PageTemplate class="account-page">
                <NavigationBar />
                <PageTemplateContent>
                    <Label variant="headline2">Account</Label>

                    <Query query={CURRENT_USER_QUERY}>
                        {({ loading, error, data }) => {
                            if (loading || !data.currentUser) return "Loading...";
                            if (error) return `Error! ${error.message}`
                            const user = data.currentUser;
                            return (
                                <Card>
                                    <CardContent>
                                        <Label variant="headline3" className="account-page__card-title">{user.firstName} {user.lastName}</Label>
                                        <Label variant="caption">Email</Label>
                                        <Label variant="body1">{user.email}</Label>
                                        <Label variant="caption">Subscription Status</Label>
                                        <Label>
                                            Family Moderator is currently in-development.<br />
                                            Until official release all services are free for use.<br />
                                            We reserve the right to change pricing at any future date.
                                        </Label>
                                        {/* 
                                        {user.subscriptionStatus === 'PAID'
                                            ? (
                                                <>
                                                    <Label variant="body1">Subscribed!</Label>
                                                    <CancelSubscription />
                                                </>
                                            )
                                            : (
                                                <>
                                                    <Label variant="body1">Not subscribed to Family Moderator</Label>

                                                    <Mutation mutation={CREATE_SUCSCRIPTION_MUTATION}>
                                                        {mutate => (
                                                            <div className="account-page__subscription-cards">
                                                                <Card className="account-page__subscription-card" variant="subtle">
                                                                    <CardContent>
                                                                        <Label variant="headline5">Family Moderator Monthly</Label>
                                                                        <Label>$10 / month</Label>
                                                                        <StripeCheckout
                                                                            name="Family Moderator Monthly"
                                                                            email={user.email}
                                                                            token={async token => {
                                                                                await mutate({
                                                                                    variables: { source: token.id, ccLast4: token.card.last4 }
                                                                                });
                                                                            }}
                                                                            stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE}
                                                                            amount={1000}
                                                                        >
                                                                            <Button variant="primary">Subscribe</Button>
                                                                        </StripeCheckout>
                                                                    </CardContent>
                                                                </Card>

                                                                <Card className="account-page__subscription-card" variant="subtle">
                                                                    <CardContent>
                                                                        <Label variant="headline5">Family Moderator Yearly</Label>
                                                                        <Label>$96 / year (20% savings)</Label>
                                                                        <StripeCheckout
                                                                            name="Family Moderator Monthly"
                                                                            email={user.email}
                                                                            token={async token => {
                                                                                const response = await mutate({
                                                                                    variables: { source: token.id, ccLast4: token.card.last4 }
                                                                                });
                                                                            }}
                                                                            stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE}
                                                                            amount={1000}
                                                                        >
                                                                            <Button variant="primary">Subscribe</Button>
                                                                        </StripeCheckout>
                                                                    </CardContent>
                                                                </Card>
                                                            </div>
                                                        )}
                                                    </Mutation>

                                                    <Modal visible={true} ref={this.modalRef}>
                                                        <Card>
                                                            <CardContent>
                                                                <Label variant="headline5">You are not currently subscribed to Family Moderator</Label>
                                                                <Label>A suscription is required to use this app.</Label>
                                                                <Button variant="primary" onClick={this.hideModal}>Okay</Button>
                                                            </CardContent>
                                                        </Card>
                                                    </Modal>
                                                </>
                                            )} */}
                                    </CardContent>
                                </Card>
                            )
                        }}
                    </Query>


                </PageTemplateContent>
            </PageTemplate>

        )
    }
}

export default SubscriptionPage
