import gql from 'graphql-tag';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Creators } from '../../../../state/actionTypes';
import Button from '../../../../uikit/components/atoms/button/button';
import Divider from '../../../../uikit/components/atoms/divider/divider';
import Label from '../../../../uikit/components/atoms/label/label';
import { List, ListItem } from '../../../../uikit/components/atoms/list/list';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { contactFragment } from '../../graphql/fragments';
import styles from './contacts-list-page.module.scss';

export class _ContactsListPage extends React.Component {
    state = {

    }

    goBack = () => {
        this.props.history.push('/library');
    }

    navigateAdd = () => {
        this.props.history.push('/library/contacts/create');
    }

    render() {
        const { contactsList } = this.props;

        return (

            <PageTemplate>
                {/* <PageTemplateTitle title="Library / Contacts"
                    feature="library"
                    leftButtons={<NavigationButton icon="arrow_back" onClick={this.goBack} />}
                    rightButtons={<NavigationButton icon="add" onClick={this.navigateAdd} />}
                /> */}
                <NavigationBar />
                <PageTemplateContent className={styles.page}>
                    <Label variant="headline2">Contacts</Label>

                    <Link to="/library/contacts/create">
                        <Button variant="primary">Create</Button>
                    </Link>
                    <Divider />

                    {contactsList.loading ? <LoadingIndicator />
                        : <>
                            {contactsList.currentFamily.contacts.length > 0 ?
                                <List className={styles.list}>
                                    {contactsList.currentFamily.contacts.map(contact =>
                                        <Link className={styles.listItem} to={`/library/contacts/${contact.id}`} key={contact.id}>
                                            <ListItem>
                                                <Label>
                                                    {contact.firstName} {contact.lastName}
                                                </Label>
                                            </ListItem>
                                        </Link>
                                    )}
                                </List>
                                : <div className={styles.noItemsContainer}>
                                    <p>You do not have any contacts yet</p>
                                </div>
                            }
                        </>
                    }
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}


export const CONTACTS_LIST = gql`
    query ContactsList {
        currentFamily {
            id
            contacts(orderBy: lastName_ASC) {
                ...contactFragment
            }
        }
    }
    ${contactFragment}
`;
// export const CONTACTS_LIST_FILTERED = gql`
//     query ContactsList($filterEmergency: Boolean) {
//         currentFamily {
//             id
//             contacts(where: { isEmergencyConctact: $filterEmergency }, 
//                 orderBy: lastName_ASC) {
//                 ...contactFragment
//             }
//         }
//     }
//     ${contactFragment}
//     `;

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {
        openDrawer: () => {
            dispatch(Creators.openDrawer());
        },
    }
}
export const ContactsListPage = compose(
    graphql(CONTACTS_LIST, {
        name: 'contactsList',
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
)(_ContactsListPage);