import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import Button from '../../../../uikit/components/atoms/button/button';
import Label from '../../../../uikit/components/atoms/label/label';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import { formatDateFromNow } from '../../../../util/formatDate';
import { MessageRenderer } from '../message-renderer/message-renderer';
import './discussion-messages.scss';

const PAGE_SIZE = 20;

export class _DiscussionMessages extends React.Component {

    state = {
        discussion: null,
        hasMoreItems: true,
        isScrolledToBottom: false,
        justReceivedNewMessage: false,
        justFetchedOldMessages: false,
    };

    componentWillMount() {
        this.unsuscribeMessages = this.subscribeToMessages();
        this.unsuscribeReceipts = this.subscribeToReceipts();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        if (this.unsubscribeMessages) {
            this.unsubscribeMessages();
        }
        if (this.unsubscribeReceipts) {
            this.unsubscribeReceipts();
        }

        window.removeEventListener('scroll', this.handleScroll);
    }

    subscribeToMessages = () => this.props.messageFeed.subscribeToMore({
        document: DISCUSSION_MESSAGE_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) {
                return prev;
            }
            console.log(subscriptionData);
            this.setState({ justReceivedNewMessage: true });
            return {
                ...prev,
                messages: [subscriptionData.data.createdMessages, ...prev.messages],
            };
        },
    });

    subscribeToReceipts = () => this.props.discussionReceipts.subscribeToMore({
        document: DISCUSSION_RECEIPT_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData || !subscriptionData.data.updatedReceipts) {
                return prev;
            }

            console.log(subscriptionData);

            const updated = subscriptionData.data.updatedReceipts;
            let receipts = prev.readReceipts;
            console.log(subscriptionData.data, updated);

            let receiptsUpdated = receipts.slice(0);
            receiptsUpdated = receiptsUpdated.filter(r => r.id !== updated.id);
            return {
                ...prev,
                readReceipts: [
                    ...receiptsUpdated,
                    updated,
                ]
            };
        },
    });

    scrollToBottom = () => {
        const scrollingElement = (document.scrollingElement || document.body);
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
    }

    handleScroll = (evt) => {
        // A buffer for detecting if user is scrolled to within this amount of the bottom of the page 
        const bottomBuffer = 25;
        const isScrolledToBottom = (window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight - bottomBuffer);
        this.setState({
            isScrolledToBottom
        });
    }

    setRead = () => {
        if (!this.state.discussion) {
            return;
        }

        this.props.setReadReceipt({
            variables: {
                dId: this.props.messageFeed.discussion.id,
            }
        })
    }

    componentWillReceiveProps(nextprops) {
        const currentMessageFeed = this.props.messageFeed;
        const currentMessages = _.get(currentMessageFeed, 'messages');

        const nextMessageFeed = nextprops.messageFeed;
        const nextMessages = _.get(nextMessageFeed, 'messages');

        const read = () => {
            if (nextMessages.length > 0) {
                const lastItem = nextMessages[0];
                this.props.setReadReceipt({
                    variables: {
                        mId: lastItem.id
                    }
                });
            }
        }

        // If we're loading new messages or if for 
        // some reason the messages are the same, let's 
        // force scroll to bottom
        if (
            (!currentMessages && nextMessages)
            // || currentMessages && currentMessages.length === nextMessages.length
        ) {
            setTimeout(() => {
                this.scrollToBottom();
                read();
            }, 120)
            return;
        }


        // if we're scrolled to top
        if (window.pageYOffset < 100
            // && !this.state.isScrolledToBottom
            && this.state.justFetchedOldMessages
            && currentMessages
            && nextMessages
            && currentMessages.length !== nextMessages.length
        ) {
            const heightBeforeRender = document.body.scrollHeight;
            setTimeout(() => {
                document.body.scrollTop = document.body.scrollHeight - heightBeforeRender;
            }, 120);
            // if we just received a new message
        } else if (this.state.isScrolledToBottom
            && this.state.justReceivedNewMessage
            && nextMessages
            && currentMessages.length !== nextMessages.length
        ) {
            setTimeout(() => {
                this.scrollToBottom();
                read();
            }, 100);
        }


        this.setState({ justFetchedOldMessages: false, justReceivedNewMessage: false });
    }

    fetch = () => {
        const { messageFeed } = this.props;
        if (!messageFeed.messages) { return };
        const last = messageFeed.messages.slice(-1)[0];
        const cursor = last ? last.id : null;

        messageFeed.fetchMore({
            variables: {
                count: PAGE_SIZE,
                cursor: cursor
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                this.setState({ justFetchedOldMessages: true });
                return {
                    ...prev,
                    messages: [...prev.messages, ...fetchMoreResult.messages]
                }
            }
        })
    }


    render() {
        const { messageFeed, discussionReceipts, currentUser } = this.props;
        const loading = messageFeed.loading || discussionReceipts.loading;
        const error = messageFeed.error;
        if (loading) return <LoadingIndicator />;
        if (error) return `Error! ${error}`;

        let renderMessages = [...messageFeed.messages].slice();
        renderMessages.length = renderMessages.length;
        renderMessages = renderMessages.reverse();

        const readReceipts = discussionReceipts.readReceipts || [];
        return (
            <div className="discussion-messages">
                {(messageFeed.loading) ? 'Loading...' : (
                    <React.Fragment>
                        <Button variant="subtle" onClick={this.fetch}>Load older messages</Button>
                        {renderMessages.map(m => {
                            const isSender = m.user.id === currentUser.id;
                            const otherReceipts = readReceipts.filter(r => r.user.id !== currentUser.id);
                            return (
                                <React.Fragment key={`message-${m.id}`}>
                                    <MessageRenderer
                                        message={m}
                                        senderName={m.user.firstName}
                                        isSender={isSender} />
                                    {
                                        otherReceipts.map(r => {
                                            if (r.lastRead.id === m.id) {
                                                return <Label key={`receipt${r.id}`}> {r.user.firstName} last read {formatDateFromNow(r.lastReadAt)}</Label>
                                            }
                                            return null;
                                        })
                                    }
                                </React.Fragment>
                            )
                        })}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const DISCUSSION_MESSAGE_FEED = gql`
    query DiscussionMessageFeed($pageSize: Int!, $cursor: ID) {
        currentUser {
            id
        }
        messages(count: $pageSize, cursor: $cursor) {
            id
            user {
                id
                firstName
                lastName
            }
            body
            attachedDocument {
                title
                localUrl
            }
            # lastReadAt
            createdAt
        }
    }
`;

const DISCUSSION_RECEIPTS = gql`
    query DiscussionReceipts {
        readReceipts {
            id
            user {
                id
                firstName
                lastName
            }
            lastRead {
                id
            }
            lastReadAt
            createdAt
        }
    }
`;

const DISCUSSION_MESSAGE_SUBSCRIPTION = gql`
    subscription CreatedMessages {
        createdMessages {
            id
            user {
                id
                firstName
                lastName
            }
            attachedDocument {
                # id
                title
                localUrl
            }
            body
            imageUrl
            createdAt
        }
    }
`
const DISCUSSION_RECEIPT_SUBSCRIPTION = gql`
    subscription UpdatedReceipts {
        updatedReceipts {
            id
            user {
                id
                firstName
                lastName
            }
            lastRead {
                id
            }
            lastReadAt
            createdAt
        }
    }
`

const DISCUSSION_SET_READ = gql`
    mutation SetReadReceipt($mId: ID!) {
        setReadReceipt(messageId: $mId) {
            id
        }
    }
`

const mapStateToProps = state => {
    return {
        currentUser: state.Auth.currentUser
    }
}
const mapDispatchToProps = dispatch => {
    return {}
}

export const DiscussionMessages = compose(
    graphql(DISCUSSION_MESSAGE_FEED, {
        name: 'messageFeed',
        options: {
            fetchPolicy: 'network-only',
            pollInterval: 1000 * 30, // fetch time
            variables: {
                pageSize: PAGE_SIZE
            }
        }
    }),
    graphql(DISCUSSION_RECEIPTS, {
        name: 'discussionReceipts',
        options: {
            fetchPolicy: 'network-only',
        }
    }),
    graphql(DISCUSSION_SET_READ, {
        name: 'setReadReceipt'
    }),
    connect(mapStateToProps, mapDispatchToProps),
)(_DiscussionMessages);
