import React, { Component } from 'react'
import gql from 'graphql-tag';
import { Query } from 'react-apollo'
import styles from './event-detail-page.module.scss';
import { formatDate } from '../../../../util/formatDate';
import Label from '../../../../uikit/components/atoms/label/label';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import { PageTemplateContent, PageTemplate } from '../../../../uikit/components/molecules/page-template/page-template';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { Card, CardContent } from '../../../../uikit/components/atoms/card/card';

export class CalendarEventDetailPage extends Component {

    goBack = () => {
        this.props.history.replace('/calendar')
    }

    render() {
        const { match } = this.props;
        return (
            <PageTemplate>
                <NavigationBar />
                <PageTemplateContent className={styles.page}>
                    <Label variant="headline2">Calendar Event</Label>
                    <Query query={CALENDAR_EVENT_DETAIL} variables={{ eventId: match.params.id }}>
                        {({ data, loading, error }) => {
                            if (loading) {
                                return (
                                    <LoadingIndicator />
                                )
                            }
                            return (
                                <Card>
                                    <CardContent>
                                        <Label variant="headline4">{data.calendarEvent.title}</Label>
                                        {data.calendarEvent.startDate && <Label>Starts: {formatDate(data.calendarEvent.startDate)}</Label>}
                                        {data.calendarEvent.endDate && <Label>Ends: {formatDate(data.calendarEvent.endDate)}</Label>}
                                        {data.calendarEvent.note && <Label>Note: {data.calendarEvent.note}</Label>}
                                    </CardContent>
                                </Card>
                            )
                        }}
                    </Query>
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}

const CALENDAR_EVENT_DETAIL = gql`
    query CalendarEventDetail($eventId: ID!) {
        calendarEvent(id: $eventId) {
            id
            title
            startDate
            endDate
            note
        }
    }
`;

export default CalendarEventDetailPage
