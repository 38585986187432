import { withFormik } from 'formik';
import React from 'react';
import { withApollo } from 'react-apollo';
import { compose } from 'recompose';
import * as Yup from 'yup';
import styles from './onboarding-profile-form.module.scss';
import gql from 'graphql-tag';
import withRouter from 'react-router-dom/withRouter';
import { TextField } from '../../../../uikit/components/atoms/text-field/text-field';
import Button from '../../../../uikit/components/atoms/button/button';


class _OnboardingProfileForm extends React.Component {
    render() {
        const {
            values,
            errors,
            touched,
            handleChange,
            handleSubmit
        } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className={styles.primaryInfo}>
                    <div className={styles.inputContainer}>
                        <TextField
                            name="firstName"
                            label="First Name"
                            placeholder="First Name"
                            value={values.firstName}
                            invalid={errors.firstName && touched.firstName}
                            error={errors.firstName}
                            onChange={handleChange} />
                    </div>
                    <div className={styles.inputContainer}>
                        <TextField
                            name="lastName"
                            label="Last Name"
                            placeholder="Last Name"
                            value={values.lastName}
                            invalid={errors.lastName && touched.lastName}
                            error={errors.lastName}
                            onChange={handleChange} />
                    </div>
                </div>
                {/* <div className={styles.additionalInfo}>
                    <TextField
                        name="email"
                        label="Email"
                        placeholder="email"
                        value={values.email}
                        invalid={errors.email && touched.email}
                        error={errors.email}
                        onChange={handleChange} />
                </div> */}
                <Button type="submit" variant="primary">Submit</Button>
            </form>
        ); 
    }
}


export const OnboardingProfileForm = compose(
    withApollo,
    withRouter,
    withFormik({
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .required('First Name is required'),
            lastName: Yup.string()
                .required('Last Name is required'),
            // email: Yup.string()
            //     .email('Email Address must be valid')
            //     .required('Email Address is required')
        }),
        mapPropsToValues(props) {
            const { currentUser } = props;
            return {
                firstName: currentUser ? currentUser.firstName : '',
                lastName: currentUser ? currentUser.lastName : '',
                email: currentUser ? currentUser.email : '',
            }
        },
        handleSubmit(values, { props, setSubmitting }) {
            setSubmitting(true);

            props.client.mutate({
                mutation: gql`
                    mutation confirmProfile {
                        confirmOnboardingProfile {
                            id
                            firstName 
                        }
                    }
                `
            })
                .then(res => {
                    // this.props.setLoggedIn(true, )
                    props.history.push('/onboarding/invite')
                })
                .catch(err => {

                })
            setSubmitting(false);
        }
    })
)(_OnboardingProfileForm);