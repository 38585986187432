import gql from 'graphql-tag';
import React, { PureComponent } from 'react';
import Button from '../../../uikit/components/atoms/button/button';
import { Card, CardContent } from '../../../uikit/components/atoms/card/card';
import Divider from '../../../uikit/components/atoms/divider/divider';
import Label from '../../../uikit/components/atoms/label/label';
import { FormField } from '../../../uikit/components/molecules/form-field/form-field';
import { NavigationBar } from '../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { PageTemplate, PageTemplateContent } from '../../../uikit/components/molecules/page-template/page-template';
import './settings-home-page.scss';
import { Mutation, graphql } from 'react-apollo';
import { LoadingIndicator } from '../../../uikit/components/atoms/loading-indicator/loading-indicator';
import { compose } from 'recompose';

const NOTIFICATION_SETTINGS_QUERY = gql`
    query NotificationSettingsQuery {
        currentUser {
            id
            notificationSettings {
                emailNotifications
            }
        }
    }
`;

const UPDATE_NOTIFICATION_SETTINGS_MUTATION = gql`
    # Try to write your query here
    mutation UpdateNotificationSettingsMutation($emailNotifications: Boolean!) {
        updateNotificationSettings(input: {
            emailNotifications: $emailNotifications
        }) {
            id
            notificationSettings {
                emailNotifications
            }
        }
    }
`;

class _SettingsHomePage extends PureComponent {
    static propTypes = {}

    state = {
        emailNotifications: true
    }

    constructor(props) {
        super(props);
        const set = props.notificationSettings;
        if (set.currentUser) {
            this.state = { emailNotifications: set.currentUser.notificationSettings.emailNotifications };
        }
    }

    componentDidUpdate(prevProps) {
        const set = this.props.notificationSettings;
        const prevset = prevProps.notificationSettings;
        if (set && prevset && set.loading === false && prevset.loading === true) {
            this.setState({ emailNotifications: set.currentUser.notificationSettings.emailNotifications })
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.checked;
        this.setState({
            emailNotifications: value
        });
    }
    tryMutate = (mutate) => {
        console.log(mutate, this.state);
        mutate({
            variables: {
                emailNotifications: this.state.emailNotifications
            }
        }).then((response) => {
            console.log(response);
        })
    }

    render() {

        const { notificationSettings: { currentUser, loading, error } } = this.props;
        return (
            <PageTemplate>
                <NavigationBar />
                <PageTemplateContent className="settings-home-page">
                    <Label variant="headline2">Settings</Label>
                    <Card>
                        <CardContent>
                            <Label variant="subtitle1">Notification Settings</Label>
                            <Divider />
                            {loading && <LoadingIndicator />}
                            {error && <Label>{error}</Label>}
                            {currentUser && <>
                                <FormField
                                    variant="checkbox"
                                    name="emailNotifications"
                                    label="Receive Email Notifications"
                                    value={this.state.emailNotifications}
                                    onChange={this.handleChange}
                                    defaultChecked={currentUser.notificationSettings.emailNotifications}
                                />
                                <Mutation mutation={UPDATE_NOTIFICATION_SETTINGS_MUTATION}>
                                    {(mutate, { data }) => (
                                        <Button
                                            className="settings-home-page__save-button"
                                            variant="primary"
                                            onClick={() => this.tryMutate(mutate)}>Save</Button>
                                    )}
                                </Mutation>
                            </>}
                        </CardContent>
                    </Card>
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}

export const SettingsHomePage = compose(
    graphql(NOTIFICATION_SETTINGS_QUERY, {
        name: 'notificationSettings',
    }),
    // graphql(),
)(_SettingsHomePage)