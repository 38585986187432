import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CalendarToolbar } from '../calendar-toolbar/calendar-toolbar';
import { Calendar as FullCalendar } from 'fullcalendar';
import 'fullcalendar/dist/plugins/moment';
// import 'fullcalendar/plugins/moment'; // need this! or include <script> tag instead
import 'fullcalendar/dist/fullcalendar.css';
import './calendar.scss';
import './calendar-reset.scss';

export class Calendar extends React.Component {

    static propTypes = {
        eventSource: PropTypes.func.isRequired,
        onEventUpdate: PropTypes.func.isRequired,
        onEventClick: PropTypes.func.isRequired,
    }

    state = {
        title: ''
    }

    listener;

    constructor() {
        super();
        this.calendarRef = React.createRef();
        this.parentRef = React.createRef();
    }


    componentDidMount() {

        let savedView = localStorage.getItem('calendarView');
        savedView = savedView || 'month'
        savedView = 'month';
        this.calendar = new FullCalendar(this.calendarRef.current, {
            header: {
                // left: 'title',
                // right: 'prev,next agendaWeek,agendaThreeDay,agendaDay'
            },
            events: this.props.eventSource,
            defaultView: 'month', //savedView,
            // defaultDate: moment(),
            navLinks: false, // can click day/week names to navigate views
            editable: true,
            longPressDelay: 500,
            eventLimit: true, // allow "more" link when too many events
            slotEventOverlap: false,
            height: document.body.offsetHeight - 64,
            columnHeaderFormat: {
                agendaWeek: 'ddd'
            },
            datesRender: ({ view }) => {
                this.setState({ title: view.title });
            },
            eventClick: (args) => {
                this.props.onEventClick(args);
            },
            eventDrop: (args) => {
                this.props.onEventUpdate(this.calendar, args);
            },
            eventResize: (args) => {
                this.props.onEventUpdate(this.calendar, args);
            },
            views: {
                month: {
                    columnHeaderFormat: 'ddd',
                },
                agenda: {
                    columnHeaderFormat: 'D\nddd',
                },
                basicThreeDay: {
                    type: 'basic',
                    duration: { days: 3 },
                    buttonText: '3 Day',
                },
                agendaThreeDay: {
                    type: 'agenda',
                    duration: { days: 3 },
                    buttonText: '3 Day',
                },
            },
        });
        this.calendar.render();

        this.listener = window.addEventListener('resize', this.handleResize);
        window.requestAnimationFrame(() => {
            this.handleResize();
        })
    }

    componentWillUnmount() {
        this.calendar.destroy();
        window.removeEventListener(this.listener, this.handleResize);
    }

    setOption = (optionName, value) => {
        this.calendar.setOption(optionName, value);
        // this.calendar.render();
    }

    handleResize = () => {
        if (!this.parentRef.current) {
            return;
        }
        const height = this.parentRef.current.offsetHeight;
        // const width = this.parentRef.current.offsetWidth;
        if (this.parentRef.current) {
            this.setOption('height', height + 16);
        }
    }

    handleViewChange = (view) => {
        localStorage.setItem('calendarView', view);
        this.calendar.changeView(view, this.calendar.currentDate);
    }


    handleDateRange = (type) => {
        switch (type) {
            case 'next': {
                this.calendar.next();
                break;
            }
            case 'prev': {
                this.calendar.prev();
                break;
            }
            case 'today': {
                this.calendar.today();
                break;
            }
            default: {
                break;
            }
        }
    }

    render() {
        return (
            <div className="calendar-component">
                <div className="calendar-component__toolbarContainer">
                    <CalendarToolbar title={this.state.title}
                        onDateRangeUpdate={this.handleDateRange}
                        onViewChange={this.handleViewChange}
                    />
                </div>
                <div ref={this.parentRef} className="calendar-component__calendarContainer">
                    <div className={classnames('fullcal-calendar-element', 'calendar-component__target-element')} ref={this.calendarRef}></div>
                </div>
            </div>
        )
    }
}