import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { childrenType } from '../../../internal/childrenPropType';
import './page-template.scss';
import classnames from 'classnames';

export class PageTemplate extends PureComponent {
    static propTypes = {
        children: childrenType,
        className: PropTypes.string,
    }

    render() {
        const { children, className: cn } = this.props;
        return (
            <div className={classnames('page-template', cn)}>
                {children}
            </div>
        )
    }
}

export class PageTemplateContent extends PureComponent {
    static propTypes = {
        children: childrenType,
        className: PropTypes.string,
    }

    render() {
        const { children, className: cn } = this.props;

        return (
            <div className={classnames('page-template__content', cn)}>
                {children}
            </div>
        )
    }
}

export default PageTemplate
