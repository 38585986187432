import React, { Component } from 'react'
import classnames from 'classnames';
import styles from './document-search.module.scss';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Card, CardContent } from '../../atoms/card/card';
import Label from '../../atoms/label/label';
import { TextField } from '../../atoms/text-field/text-field';
import { LoadingIndicator } from '../../atoms/loading-indicator/loading-indicator';

export class DocumentSearch extends Component {

    state = {
        value: '',
    }

    handleChange = (event) => {
        this.setState({ value: event.target.value });
    }

    handleClick = (document) => {
        this.props.onDocumentSelected(document);
    }

    render() {
        const { className: cName } = this.props;
        return (
            <Card elevation="medium" className={cName}>
                <CardContent className={classnames(
                    styles.cardContent,
                )}>
                    <Label variant="subtitle1">Select a Document</Label>
                    <TextField
                        label="Search by Title"
                        placeholder="Search by Title"
                        value={this.state.value}
                        onChange={this.handleChange} />
                    <Query query={SEARCH_DOCUMENTS_QUERY}
                        variables={{ searchTerm: this.state.value }}>
                        {({ data, loading, error }) => {
                            if (loading) return <LoadingIndicator />;
                            return (
                                <ul>
                                    {data.searchDocuments ? data.searchDocuments.slice(0, 5).map((d) => {
                                        return (
                                            <li
                                                onClick={() => this.handleClick(d)}
                                                key={d.id}
                                                className={styles.listItem}>
                                                <Label className={styles.listItemText}>{d.title}</Label>
                                            </li>
                                        );
                                    }) : null}
                                </ul>
                            );
                        }}
                    </Query>
                </CardContent>
            </Card>
        )
    }
}

const SEARCH_DOCUMENTS_QUERY = gql`
    query DocumentSearch($searchTerm: String!) {
        searchDocuments(searchTerm: $searchTerm) {
            id
            title
            localUrl
        }
    }
`;


export default DocumentSearch
