import * as React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Button from '../../../uikit/components/atoms/button/button';
import Label from '../../../uikit/components/atoms/label/label';

import { Modal } from '../../../uikit/components/molecules/modal/modal';
import { Card, CardContent } from '../../../uikit/components/atoms/card/card';

import './cancel-subscription.scss';

const CANCEL_STRIPE_SUBSCRIPTION_MUTATION = gql`
    mutation CancelStripeSubscriptionMutation {
        cancelStripeSubscription {
            id
            subscriptionStatus
        }
    }
 `;

export class CancelSubscription extends React.PureComponent {

    constructor(props) {
        super(props);
        this.confirmRef = React.createRef();
    }

    showModal = () => {
        this.confirmRef.current.show();
    }

    hideModal = () => {
        this.confirmRef.current.hide();
    }

    render() {
        return (
            <Mutation mutation={CANCEL_STRIPE_SUBSCRIPTION_MUTATION}>
                {mutate => (
                    <div className="cancel-subscription">
                        <Button variant='link' onClick={this.showModal}>Cancel Subscription</Button>

                        <Modal ref={this.confirmRef}>
                            <Card>
                                <CardContent>
                                    <Label variant="headline4">Cancel Subscription</Label>
                                    <Label>Are you sure you want to cancel your Family Moderator subscription?</Label>
                                    <Label>You will lose all access to all features within the application.</Label>

                                    <div className="cancel-subscription__action-buttons">
                                        <Button variant='primary' onClick={() => mutate()}>Cancel Subscription</Button>
                                        <Button variant='subtle' onClick={this.hideModal}>Never Mind</Button>
                                    </div>

                                </CardContent>
                            </Card>

                        </Modal>
                    </div>
                )}
            </Mutation>
        );
    }
}