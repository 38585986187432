
let isNgrok = false;
let isHeroku = false;
let isReal = false;
let isLocal = false;

if (window.location.href.includes('ngrok')) {
    isNgrok = true;
}
if (window.location.href.includes('localhost')) {
    isLocal = true;
}

if (!isLocal && !isNgrok && !isHeroku) {
    isReal = true;
}
let obj = {
    apiUrl: 'http://localhost:4000',
    baseUrl: 'http://localhost:4000',
    subUrl: 'ws://localhost:4000',
}

if (isNgrok) {
    obj = {
        apiUrl: 'http://familymoderator.ngrok.io',
        baseUrl: 'http://familymoderator.ngrok.io',
        subUrl: 'ws://familymoderator.ngrok.io',
    }
} else if (isReal) {
    obj = {
        apiUrl: 'https://api.familymoderator.com',
        baseUrl: 'https://familymoderator.com',
        subUrl: 'wss://api.familymoderator.com',
    }
}

export default obj;
