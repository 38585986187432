import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { connect, Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import withRouter from 'react-router-dom/withRouter';
import './App.scss';
import { CURRENT_USER_WITH_FAMILY_QUERY } from './graphql/queries';
import { Routes } from './modules/routes';
import { Creators } from './state/actionTypes';
import { nonPersistedStore } from './state/store';
import { LoadingIndicator } from './uikit/components/atoms/loading-indicator/loading-indicator';
import { Modal } from './uikit/components/molecules/modal/modal';
import { createClient } from './util/createClient';


class _App extends React.Component {

    state = {
        errorText: ''
    };

    constructor(props) {
        super(props);
        this.errorDialogRef = React.createRef();
        this.loadingDialogRef = React.createRef();
        this.client = createClient(this.props.alertError);

        this.client.query({
            query: CURRENT_USER_WITH_FAMILY_QUERY
        })
            .then(res => {
                if (res.data.currentUser) {
                    if (res.data.currentUser.family) {
                        this.props.setFamilyLoaded(true);
                    }
                    //TODO: Remove this
                    props.setLoggedIn(true, res.data.currentUser);
                }
                props.setAppLoaded();
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.errorText !== this.props.errorText) {
            // if (this.props.errorText != '' && this.props.errorText !== null) {
            //     this.errorDialogRef.current.show();
            // }
        }
        if (prevProps.loading !== this.props.loading) {
            if (this.props.loading) {
                this.loadingDialogRef.current.show();
            } else {
                this.loadingDialogRef.current.hide();
            }
        }
    }

    render() {
        return (
            <ApolloProvider client={this.client}>
                <React.Fragment>
                    {/* 
                        <AlertDialog ref={this.errorDialogRef} title="An error has occurred" body={this.props.errorText} />
                    */}
                    <Modal ref={this.loadingDialogRef}>
                        <LoadingIndicator />
                    </Modal>
                    <Routes />
                </React.Fragment>
            </ApolloProvider>
        );
    }
}



const mapStateToProps = state => {
    return {
        loading: state.UI.loadingModal,
        appLoaded: state.UI.appLoaded,
        errorText: state.UI.errorText,
        loggedIn: state.Auth.loggedIn,
        token: state.Auth.token,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMediaMatch: (media) => {
            dispatch(Creators.setMediaMatch(media));
        },
        setAppLoaded: () => {
            dispatch(Creators.setAppLoaded());
        },
        setLoggedIn: (log, usr) => {
            dispatch(Creators.setLoggedIn(log, usr));
        },
        setAuthToken: (token) => {
            dispatch(Creators.setAuthToken(token))
        },
        setFamilyLoaded: (val) => {
            dispatch(Creators.setFamilyLoaded(val));
        },
        alertError: (error) => {
            dispatch(Creators.alertError(error));
        }
    }
}


const Connected = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(
    _App
));

const App = () => (
    <Provider store={nonPersistedStore}>
        <BrowserRouter>
            <Connected />
        </BrowserRouter>
    </Provider>
)

export default App;



