import classnames from 'classnames';
import React from 'react';
import Label from '../../../../uikit/components/atoms/label/label';
import { formatDateFromNow } from '../../../../util/formatDate';
// import { Image } from '../../../../components/atoms/image/image';
import './message-renderer.scss';
import { Image } from '../../../../uikit/components/atoms/image/image';

export class MessageRenderer extends React.Component {
    render() {
        const { message, isSender, senderName } = this.props;
        return (
            <div className={classnames(
                'message-renderer',
                {
                    'message-renderer--is-sender': isSender
                }
            )}>  

                <div className="message-renderer__message-inner">
                    <Label variant="caption" className="message-renderer__sender-name">
                        {isSender
                            ? "You"
                            : senderName
                        }
                    </Label>
                    <Label variant="caption" className="message-renderer__time-stamp">
                        {formatDateFromNow(message.createdAt)}
                    </Label>
                    {message.attachedDocument
                        ? <Image preview className="message-renderer__message-image" src={message.attachedDocument.localUrl} />
                        : null
                    }
                    <Label className="message-renderer__message-body">
                        {message.body}
                    </Label>
                </div>

            </div>
        );
    }
}