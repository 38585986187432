import moment from 'moment';

export const formatDate = (dateTime) => {
    return moment(dateTime).format('lll');
}

export const formatDateFromNow = (dateTime) => {
    // moment(dateTime).fromNow();
    return moment(dateTime).fromNow();
}
/*
moment.locale();         // en
moment().format('LT');   // 9:02 PM
moment().format('LTS');  // 9:02:29 PM
moment().format('L');    // 07/19/2018
moment().format('l');    // 7/19/2018
moment().format('LL');   // July 19, 2018
moment().format('ll');   // Jul 19, 2018
moment().format('LLL');  // July 19, 2018 9:02 PM
moment().format('lll');  // Jul 19, 2018 9:02 PM
moment().format('LLLL'); // Thursday, July 19, 2018 9:02 PM
moment().format('llll'); // Thu, Jul 19, 2018 9:02 PM*/