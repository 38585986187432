// sampleReducer.js
import { createReducer } from 'reduxsauce'
import { Types } from './actionTypes'

// the initial state of this reducer
export const INITIAL_STATE = {
    token: null,
    loggedIn: false,
    currentUser: null,
}

const setToken = (state = INITIAL_STATE, action) => {
    return { ...state, token: action.token, loggedIn: true }
}

const setLoggedIn = (state = INITIAL_STATE, action) => {
    return { ...state, loggedIn: action.loggedIn, currentUser: action.user }
}

// map our action types to our reducer functions
export const HANDLERS = {
    [Types.SET_AUTH_TOKEN]: setToken,
    [Types.SET_LOGGED_IN]: setLoggedIn,
}

export default createReducer(INITIAL_STATE, HANDLERS)