import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';

const root = document.getElementById('root');

function checkToken() {
    const getParameterByName = (name, url) => {
        // if (!url) {
        //   url = window.location.href;
        // }
        // Used to be this /[/[\]]/g
        const newname = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${newname}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const token = getParameterByName('token', window.location);
    if (token) {
        window.localStorage.setItem('refreshToken', token);
        window.location.replace(config.baseUrl);
    }

    setTimeout(() => {
        const storedToken = localStorage.getItem('refreshToken');
        if (storedToken) {
            window.postMessage(storedToken, '*');
        }
    }, 500)

}


const load = () => ReactDOM.render((
    <App />
), root);


// This is needed for Hot Module Replacement
if (module.hot) {
    module.hot.accept('./App', load);
}

checkToken();
load();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
