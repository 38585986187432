import gql from 'graphql-tag';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import './documents-home-page.scss';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import Divider from '../../../../uikit/components/atoms/divider/divider';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import Label from '../../../../uikit/components/atoms/label/label';
import Button from '../../../../uikit/components/atoms/button/button';
import { List, ListItem } from '../../../../uikit/components/atoms/list/list';

class _DocumentsHomePage extends Component {

    goBack = () => {
        this.props.history.push('/library');
    }

    render() {
        return (
            <PageTemplate>
                <NavigationBar />
                {/* <PageTemplateTitle
                    feature="library"
                    title="Library / Documents"
                    leftButtons={<NavigationButton icon="arrow_back" onClick={this.goBack} />}
                /> */}
                <PageTemplateContent className="documents-home-page">
                    <Label variant="headline2">Documents</Label>
                    <Link to="/library/documents/upload" className="documents-home-page__upload-button">
                        <Button variant="primary">Upload</Button>
                    </Link>
                    <Divider />
                    {this.props.family.loading
                        ? <LoadingIndicator />
                        : <List>
                            {this.props.family.currentFamily.documents.map(d => {
                                return (
                                    <Link to={`/library/documents/${d.id}`} key={d.id}>
                                        <ListItem>
                                            <Label>{d.title}</Label>
                                        </ListItem>
                                    </Link>
                                )
                            })}
                        </List>
                    }
                </PageTemplateContent>
            </PageTemplate>
        )
    }

}

const FAMILY_DOCUMENTS = gql`
    query FamilyDocuments {
        currentFamily {
            id
            documents {
                id
                title
            }
        }
    }
`
export const DocumentsHomePage = compose(
    graphql(FAMILY_DOCUMENTS, {
        name: 'family'
    })
)(_DocumentsHomePage);
