import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './checkbox.scss';

import classnames from 'classnames';

export class Checkbox extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
    }

    render() {
        const { className: cn, ...rest } = this.props;
        return (
            <input type="checkbox"
                className={classnames('ui-checkbox', cn)}
                {...rest} />
        )
    }
}

export default Checkbox
