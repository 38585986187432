// sampleReducer.js
import { createReducer } from 'reduxsauce'
import { Types } from './actionTypes'

// the initial state of this reducer
export const INITIAL_STATE = {
    loadingModal: false,
    appLoaded: false,
    familyLoaded: false,
    drawerOpen: false,
    mediaMatch: '',
    errorText: '',
}

const setAppLoaded = (state = INITIAL_STATE, action) => {
    return { ...state, appLoaded: true }
}

const setFamilyLoaded = (state = INITIAL_STATE, action) => {
    return { ...state, familyLoaded: action.familyLoaded }
}

const setLoading = (state = INITIAL_STATE, action) => {
    return { ...state, loadingModal: action.loading }
}

const openDrawer = (state = INITIAL_STATE, action) => {
    return { ...state, drawerOpen: true }
}

const closeDrawer = (state = INITIAL_STATE, action) => {
    return { ...state, drawerOpen: false }
}

const toggleDrawer = (state = INITIAL_STATE, action) => {
    return { ...state, drawerOpen: !state.drawerOpen }
}

const alertError = (state = INITIAL_STATE, action) => {
    return { ...state, errorText: action.errorText };
}

const setMediaMatch = (state = INITIAL_STATE, action) => {
    return { ...state, mediaMatch: action.media };
}

// map our action types to our reducer functions
export const HANDLERS = {
    [Types.SET_APP_LOADED]: setAppLoaded,
    [Types.SET_FAMILY_LOADED]: setFamilyLoaded,
    [Types.SET_LOADING]: setLoading,
    [Types.OPEN_DRAWER]: openDrawer,
    [Types.CLOSE_DRAWER]: closeDrawer,
    [Types.TOGGLE_DRAWER]: toggleDrawer,
    [Types.ALERT_ERROR]: alertError,
    [Types.SET_MEDIA_MATCH]: setMediaMatch,
}

export default createReducer(INITIAL_STATE, HANDLERS)