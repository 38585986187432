import gql from 'graphql-tag';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { CalendarEventForm } from '../../components/calendar-event-form/calendar-event-form';
import styles from './calendar-create-event-page.module.scss';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import * as moment from 'moment';

// import { FETCH_FAMILY_CALENDARS_EVENTS } from '../calendar-home-page/calendar-home-page';

export class _CalendarCreateEventPage extends Component {
    goBack = () => {
        this.props.history.replace('/calendar')
    }

    submitForm = async (formProps) => {
        try {
            await this.props.createCalendarEvent({
                variables: {
                    calendarId: formProps.calendar,
                    title: formProps.title,
                    startDate: moment(formProps.startDate).toDate(),
                    endDate: moment(formProps.endDate).toDate(),
                    note: formProps.note,
                }
            });
            this.props.history.push('/calendar');
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { getFamilyCalendars } = this.props;
        return (
            <PageTemplate>
                <NavigationBar />
                <PageTemplateContent className={styles.page}>
                    {getFamilyCalendars.loading
                        ? <LoadingIndicator />
                        : <CalendarEventForm
                            onCancel={this.goBack}
                            onComplete={this.submitForm}
                            calendars={getFamilyCalendars.currentFamily.calendars} />
                    }
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}

const CREATE_CALENDAR_EVENT = gql`
    mutation CreateCalendarEvent($calendarId: ID!, $title: String!, $startDate: DateTime!, $endDate: DateTime!, $note: String!) {
        createCalendarEvent(input: {
            calendarId: $calendarId,
            title: $title,
            startDate: $startDate,
            endDate: $endDate,
            note: $note,
            allDay: false
        }) {
            id
        }
    }
`;

const GET_FAMILY_CALENDARS = gql`
    query GetFamilyCalendars {
        currentFamily {
            id
            calendars {
                id
                title
            }
        }
    }
`;

export const CalendarCreateEventPage = compose(
    graphql(CREATE_CALENDAR_EVENT, {
        name: 'createCalendarEvent',
        // options: {
        //     refetchQueries: [FETCH_FAMILY_CALENDARS_EVENTS]
        // }
    }),
    graphql(GET_FAMILY_CALENDARS, {
        name: 'getFamilyCalendars',
    })
)(_CalendarCreateEventPage);
