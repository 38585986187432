// import { environment } from 'environment';
import env from '../../../../config';
import React from 'react';
import './login-page.scss';
import Button from '../../../../uikit/components/atoms/button/button';
import { PageTemplateContent, PageTemplate } from '../../../../uikit/components/molecules/page-template/page-template';
import Label from '../../../../uikit/components/atoms/label/label';
import { Card, CardContent } from '../../../../uikit/components/atoms/card/card';

export class LoginPage extends React.PureComponent {

    componentDidMount() {
    }

    render() {
        return (
            <PageTemplate>
                <PageTemplateContent className="login-page">

                    <div className="login-page__title">
                        <Label variant="headline2">Family Moderator</Label>
                    </div>
                    <Card className="login-page__card">
                        <CardContent>
                            <Label variant="headline5">Login</Label>
                            <Label>Login to Family Moderator</Label>
                            {/* <Button variant="subtle" className="login-page__login-button">Username/Password</Button> */}
                            <a href={`${env.apiUrl}/auth/facebook`}>
                                <Button variant="primary" className="login-page__login-button">Facebook</Button>
                            </a>
                            {/* <Button secondary className={styles.loginButton}>Google</Button> */}
                        </CardContent>
                    </Card>
                </PageTemplateContent>
            </PageTemplate>
        );
    }
}

