
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MessageThreadPage } from './pages/message-thread-page/message-thread-page';

class _MessagesRoutes extends React.PureComponent {
    render() {
        const { match } = this.props;
        return (
            <React.Fragment>
                <Switch>
                    <Route path={`${match.url}/`} exact component={MessageThreadPage} />
                </Switch>
            </React.Fragment>
        )
    }
}

export const MessagesRoutes = (_MessagesRoutes);