import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import Label from '../../../../uikit/components/atoms/label/label';
import Button from '../../../../uikit/components/atoms/button/button';
import { Link } from 'react-router-dom';

export class TermsOfService extends Component {
    static propTypes = {

    }

    render() {
        return (
            <PageTemplate>
                <PageTemplateContent>
                    <Label variant="headline2">Family Moderator Terms of Service</Label>

                    <Link to="/">
                        <Button variant="link">Go Back</Button>
                    </Link>
                    
                    <Label variant="headline5">
                        1. Terms
                    </Label>
                    <Label>
                        By accessing the website at http://familymoderator.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
                    </Label>
                    <Label variant="headline5">
                        2. Use License
                    </Label>
                    <Label>
                        <ol>
                            <li>
                                Permission is granted to temporarily download one copy of the materials (information or software) on Family Moderator's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                                <ul>
                                    <li>modify or copy the materials;</li>
                                    <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                                    <li>attempt to decompile or reverse engineer any software contained on Family Moderator's website;</li>
                                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                                    <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                                </ul>
                            </li>
                            <li>
                                This license shall automatically terminate if you violate any of these restrictions and may be terminated by Family Moderator at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                            </li>
                        </ol>
                    </Label>
                    <Label variant="headline5">
                        3. Disclaimer
                    </Label>
                    <Label>
                        <ol>
                            <li>
                                The materials on Family Moderator's website are provided on an 'as is' basis. Family Moderator makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                            </li>
                            <li>
                                Further, Family Moderator does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
                            </li>
                        </ol>
                    </Label>
                    <Label variant="headline5">
                        4. Limitations
                    </Label>
                    <Label>
                        In no event shall Family Moderator or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Family Moderator's website, even if Family Moderator or a Family Moderator authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
                    </Label>
                    <Label variant="headline5">
                        5. Accuracy of materials
                    </Label>
                    <Label>
                        The materials appearing on Family Moderator's website could include technical, typographical, or photographic errors. Family Moderator does not warrant that any of the materials on its website are accurate, complete or current. Family Moderator may make changes to the materials contained on its website at any time without notice. However Family Moderator does not make any commitment to update the materials.
                    </Label>
                    <Label variant="headline5">
                        6. Links
                    </Label>
                    <Label>
                        Family Moderator has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Family Moderator of the site. Use of any such linked website is at the user's own risk.
                    </Label>
                    <Label variant="headline5">
                        7. Modifications
                    </Label>
                    <Label>
                        Family Moderator may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.
                    </Label>
                    <Label variant="headline5">
                        8. Governing Law
                    </Label>
                    <Label>
                        These terms and conditions are governed by and construed in accordance with the laws of Nebraska and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                    </Label>
                    <Link to="/">
                        <Button variant="link">Go Back</Button>
                    </Link>
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}

