import classnames from 'classnames';
import gql from 'graphql-tag';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import Button from '../../../../uikit/components/atoms/button/button';
import { TextField } from '../../../../uikit/components/atoms/text-field/text-field';
import { readFilePreview } from '../../../../util/imageUtility';
import './message-composition-bar.scss';
import { Modal } from '../../../../uikit/components/molecules/modal/modal';
import { DocumentSearch } from '../../../../uikit/components/organisms/document-search/document-search';
import { Image } from '../../../../uikit/components/atoms/image/image';

import { ReactComponent as SendIcon } from '../../../../uikit/internal/svg/gzm0098-send.svg';
import { ReactComponent as PaperClipIcon } from '../../../../uikit/internal/svg/gzm0007-paperclip.svg';

export class _MessageCompositionBar extends React.Component {

    state = {
        messageText: '',
        imagePreview: null,
        selectedFile: null,
        selectedDocument: null,
    }

    inputRef = React.createRef();
    modalRef = React.createRef();
    // fileInput = React.createRef();


    showDocumentPicker = () => {
        this.modalRef.current.show();
    }

    clickedOutside = () => {
        this.modalRef.current.hide();
    }

    clearDocument = () => {
        this.setState({
            selectedDocument: null,
        })
    }

    handleInputChange = (evt) => {
        this.setState({ messageText: evt.target.value });
    }

    handleFileChange = (selectorFiles) => {
        this.setState({
            selectedFile: selectorFiles[0]
        });
        readFilePreview(selectorFiles[0]).then((preview) => {
            this.setState({
                imagePreview: preview
            });
        });
    }

    handleDocumentChange = (document) => {
        this.setState({
            selectedDocument: document
        });
        this.modalRef.current.hide();
    }

    handleSubmit = () => {
        const trimmed = this.state.messageText.trim()
        if (trimmed !== '') {
            if (this.state.selectedDocument) {
                this.props.sendMessage({
                    variables: {
                        discussionId: this.props.discussion,
                        body: this.state.messageText,
                        attachedDocument: this.state.selectedDocument.id
                    }
                });
            } else {
                this.props.sendMessage({
                    variables: {
                        discussionId: this.props.discussion,
                        body: this.state.messageText
                    }
                })
            }

            this.setState({
                messageText: ''
            });
            // this.inputRef.current.value = '';
            // this.fileInput.current.value = '';
        }
    }

    render() {
        const { discussion, className: cn } = this.props;
        if (discussion) {
            return (
                <React.Fragment>
                    <div className={classnames(
                        'message-composition-bar',
                        cn
                    )}>
                        <div className="message-composition-bar__container">
                            <Button className="message-composition-bar__icon-button" onClick={this.showDocumentPicker}>
                                {/* <FontAwesomeIcon icon={faImages} /> */}
                                <PaperClipIcon />
                            </Button>
                            <div className="message-composition-bar__filler"></div>

                            <div className='message-composition-bar__content-editor'>
                                {this.state.selectedDocument
                                    ? <React.Fragment>
                                        <Image className="message-composition-bar__image-preview" src={this.state.selectedDocument.localUrl} />
                                        <Button variant="subtle" onClick={this.clearDocument}>Clear Document</Button>
                                    </React.Fragment>
                                    : null}
                                <TextField
                                    className='message-composition-bar__content-input'
                                    value={this.state.messageText}
                                    onChange={this.handleInputChange} />
                            </div>
                            <Button variant="primary" className="message-composition-bar__send-button" onClick={this.handleSubmit}>
                                {/* <FontAwesomeIcon icon={faArrowUp} /> */}
                                {/* <SendIcon /> */}
                                Send
                            </Button>
                        </div>
                    </div>

                    <Modal ref={this.modalRef} backdropClick={this.clickedOutside}>
                        <DocumentSearch onDocumentSelected={this.handleDocumentChange} />
                    </Modal>
                </React.Fragment >
            );
        } else {
            return null;
        }
    }
}




export const SEND_DISCUSSION_MESSAGE = gql`
    mutation SendDiscussionMessage($body: String!, $attachedDocument: ID) {
        sendMessage(body: $body, attachedDocument: $attachedDocument){
            id
            body
            createdAt
        }
    }
`
export const MessageCompositionBar = compose(
    graphql(SEND_DISCUSSION_MESSAGE, {
        name: 'sendMessage',
    }),
)(_MessageCompositionBar);
