import React from 'react';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import styles from './onboarding-invite-page.module.scss';
import Button from '../../../../uikit/components/atoms/button/button';
import Label from '../../../../uikit/components/atoms/label/label';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import { TextField } from '../../../../uikit/components/atoms/text-field/text-field';

class _OnboardingInvitePage extends React.Component {

    state = {
        email: ''
    }

    createFamily = () => {
    }

    inviteFamilyMember = () => {
        const inviteEmail = this.state.email;
        this.props.sendInvite({
            variables: {
                email: inviteEmail.toLowerCase()
            }
        }).then(() => {
            this.setState({
                'email': ''
            });
        })
    }

    cancelInvite = (id) => {
        this.props.cancelInvite({
            variables: {
                id
            }
        });
    }

    acceptInvite = (id) => {
        this.props.acceptInvite({
            variables: {
                id
            }
        }).then(() => {
            // this.props.history.push('/')
            window.location.reload();
        })
    }

    handleChange = (evt) => {
        const value = evt.target.value.trim();
        this.setState({
            email: value
        })
    }

    render() {
        const { familyInvites } = this.props;

        const loading = familyInvites.loading;
        if (!loading) {
            // const sentInvites = familyInvites.invites.filter(inv => {
            //     return inv.
            // })
        }

        const OutstandingInvites = ({ currentUser, invites }) => {
            invites = invites || [];
            const filtered = invites.filter(inv => inv.sender.id !== currentUser.id);
            if (filtered.length === 0) {
                return null;
            }

            return (
                <div className={styles.section}>
                    <Label variant="subtitle1" className={styles.sectionSubTitle}>
                        It looks like you have outstanding invites, would you like to accept?
                    </Label>
                    {
                        filtered.map(inv => (
                            <div className={styles.invite} key={inv.id}>
                                <Label className={styles.inviteText}>{inv.sender.firstName} {inv.sender.lastName} | {inv.sender.email}</Label>
                                <div>
                                    <Button className={styles.inviteActionButton}>Decline</Button>
                                    <Button className={styles.inviteActionButton}
                                        onClick={() => this.acceptInvite(inv.id)}>Accept</Button>
                                    {/* <Button className={styles.inviteActionButton}
                                            onClick={() => this.cancelInvite(inv.id)}>Cancel</Button> */}
                                </div>
                            </div>
                        ))
                    }
                </div>
            );
        }

        const SentInvites = ({ currentUser, invites }) => {
            invites = invites || [];
            const filtered = invites.filter(inv => inv.sender.id === currentUser.id);
            if (filtered.length === 0) {
                return null;
            }
            return (
                <div className={styles.section}>
                    <Label variant="subtitle1" className={styles.sectionSubTitle}>Here are invites that you have sent</Label>
                    {
                        filtered.map(inv => (
                            <div className={styles.invite} key={inv.id}>
                                <Label className={styles.inviteText}>{inv.inviteeEmail}</Label>
                                <div>
                                    <Button className={styles.inviteActionButton}
                                        onClick={() => this.cancelInvite(inv.id)}>Cancel</Button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            );
        }

        return (
            <PageTemplate>
                <PageTemplateContent>
                    <Label variant="headline2" className={styles.pageTitle}>Creating A Family</Label>
                    {/* <p className={styles.inviteText}>Here you will create the Family for you and your partner to communicate through</p> */}
                    {familyInvites.loading
                        ? <LoadingIndicator />
                        : (
                            <React.Fragment>
                                <OutstandingInvites currentUser={familyInvites.currentUser} invites={familyInvites.invites} />
                                <SentInvites currentUser={familyInvites.currentUser} invites={familyInvites.invites} />
                            </React.Fragment>
                        )
                    }
                    <div className={styles.lastSection}>
                        <Label variant="subtitle1" className={styles.sectionSubTitle}>Enter your partner's email address below to invite them</Label>
                        <TextField
                            className={styles.emailField}
                            placeholder="Partner's Email"
                            value={this.state.email}
                            onChange={this.handleChange} />
                        <Button variant="primary"
                            onClick={this.inviteFamilyMember}>Invite</Button>
                    </div>
                    {/* <InviteFamilyMember /> */}
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}


const FAMILY_INVITES = gql`
    query FamilyInvites {
        currentUser {
            id
        }
        invites {
            id
            inviteeEmail
            sender {
                id
                firstName
                lastName
                email
            }
        }
    }
`;

const SEND_INVITE = gql`
    mutation SendInvite($email: String!) { 
        sendInvite(email: $email) {
            id
            inviteeEmail
            sender {
                id
            }
        }
    }
`;


const ACCEPT_INVITE = gql`
    mutation AcceptInvite($id: ID!) {
        acceptInvite(id: $id) {
            id
            participants {
                id
                firstName
                lastName
                email
            }
        }
    }
`;

const DECLINE_INVITE = gql`
    mutation DeclineInvite($id: ID!) {
        declineInvite(id: $id)
    }
`;


const CANCEL_INVITE = gql`
    mutation CancelInvite($id: ID!) {
        cancelInvite(id: $id)
    }
`;


const CREATE_FAMILY = gql`
    mutation CreateFamily($title: String!) {
        createFamily(title: $title) {
            id
            title
        }
    }
`;

export const OnboardingInvitePage = compose(
    graphql(FAMILY_INVITES, {
        name: 'familyInvites'
    }),
    graphql(SEND_INVITE, {
        name: 'sendInvite',
        options: {
            refetchQueries: ['FamilyInvites']
        }
    }),
    graphql(ACCEPT_INVITE, {
        name: 'acceptInvite',
        options: {
            refetchQueries: ['CurrentUser', 'FamilyInvites']
        }
    }),
    graphql(DECLINE_INVITE, {
        name: 'declineInvite',
        options: {
            refetchQueries: ['FamilyInvites']
        }
    }),
    graphql(CANCEL_INVITE, {
        name: 'cancelInvite',
        options: {
            refetchQueries: ['FamilyInvites']
        }
    }),
    graphql(CREATE_FAMILY, {
        name: 'createFamily',
        options: {
            refetchQueries: ['CurrentFamily']
        }
    }),
)(_OnboardingInvitePage)