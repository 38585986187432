import React from 'react';
import { Link } from 'react-router-dom';
// import { faBooks, faCalendarAlt, faClipboardList, faCog, faComments, faHome, faUserCircle } from '../../../node_modules/@fortawesome/pro-regular-svg-icons';
import './overflow-page.scss';
import { PageTemplate, PageTemplateContent } from '../../uikit/components/molecules/page-template/page-template';
import { NavigationBar } from '../../uikit/components/molecules/navigation-bar/navigation-bar';
import { List, ListItem } from '../../uikit/components/atoms/list/list';
import Button from '../../uikit/components/atoms/button/button';


export class OverflowPage extends React.Component {

    logout = () => {
        localStorage.setItem("token", null);
        localStorage.setItem("refreshToken", null);
        this.props.history.push('/');
        window.location.reload();
    }

    render() {
        return (
            <PageTemplate className="overflow-page">
                <NavigationBar />
                <PageTemplateContent>
                    <List>
                        <Link to="/account">
                            <ListItem className="overflow-page__list-item">Account</ListItem>
                        </Link>
                        <Link to="/settings">
                            <ListItem className="overflow-page__list-item">Settings</ListItem>
                        </Link>
                        <Button
                            className="overflow-page__list-item"
                            variant="primary"
                            onClick={this.logout}>
                            Log Out
                        </Button>
                    </List>
                    {/* <List>
                            <Link to="/">
                                <ListItem>
                                    <ListItemIcon icon={faHome} />
                                    <ListItemText>Dashboard</ListItemText>
                                </ListItem>
                            </Link>
                            <Link to="/messages">
                                <ListItem>
                                    <ListItemIcon icon={faComments} />
                                    <ListItemText>Messages</ListItemText>
                                </ListItem>
                            </Link>
                        <Link to="/calendar">
                            <ListItem>
                                <ListItemIcon icon={faCalendarAlt} />
                                <ListItemText>Calendar</ListItemText>
                            </ListItem>
                        </Link>
                        <Link to="/library">
                            <ListItem>
                                <ListItemIcon icon={faBooks} />
                                <ListItemText>Library</ListItemText>
                            </ListItem>
                        </Link>
                        
                        <Link to="/reports">
                            <ListItem>
                                <ListItemIcon icon={faClipboardList} />
                                <ListItemText>Reports</ListItemText>
                            </ListItem>
                        </Link>

                        <Link to="/profile">
                            <ListItem>
                                <ListItemIcon icon={faUserCircle} />
                                <ListItemText>Profile</ListItemText>
                            </ListItem>
                        </Link>

                        <Link to="/settings">
                            <ListItem>
                                <ListItemIcon icon={faCog} />
                                <ListItemText>Settings</ListItemText>
                            </ListItem>
                        </Link>

                        </List> */}
                </PageTemplateContent>
            </PageTemplate >
        )
    }
}

