import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({

    // UI REDUCER
    setAppLoaded: null,
    setFamilyLoaded: ['familyLoaded'],
    openDrawer: null,
    closeDrawer: null,
    toggleDrawer: null,
    setLoading: ['loading'],
    alertError: ['errorText'],
    setMediaMatch: ['media'],

    // AUTH REDUCER
    setAuthToken: ['token'],
    setLoggedIn: ['loggedIn', 'user'],
}, {}) // options - the 2nd parameter is optional