
import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Creators } from '../../../../state/actionTypes';
import { DiscussionMessages } from '../../components/discussion-messages/discussion-messages';
import { MessageCompositionBar } from '../../components/message-composition-bar/message-composition-bar';
import './message-thread-page.scss';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';

export const GET_DISCUSSION = gql`
    query Discussion {
        currentFamily {
            id
            discussion {
                id
                title
                createdAt
                updatedAt
            }
        }
    }
`

export class _MessageThreadPage extends React.Component {

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <PageTemplate>
                <NavigationBar />
                <PageTemplateContent className="message-thread-page">
                    <Query query={GET_DISCUSSION}>
                        {({ loading, error, data }) => {
                            if (loading) return <LoadingIndicator />;
                            if (error) return `Error! ${error.message}`;
                            const discussion = data.currentFamily.discussion;
                            return (
                                <React.Fragment>
                                    <div className="message-thread-page__thread-container">
                                        {discussion ? <DiscussionMessages discussion={discussion.id} /> : null}
                                    </div>
                                    <div className="message-thread-page__thread-footer-container">
                                        <MessageCompositionBar className="message-thread-page__thread-footer" discussion={discussion.id} />
                                    </div>
                                </React.Fragment>
                            )
                        }}
                    </Query>
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        openDrawer: () => {
            dispatch(Creators.openDrawer());
        }
    }
}

export const MessageThreadPage = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(_MessageThreadPage);
