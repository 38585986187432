import gql from 'graphql-tag';
import * as moment from 'moment';
import React from 'react';
import { withApollo } from 'react-apollo';
import { compose } from 'redux';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { Calendar } from '../../components/calendar/calendar';
import './calendar-home-page.scss';
import { toDuration } from 'fullcalendar/dist/plugins/moment';



class _CalendarHomePage extends React.Component {

    calendarEventSource = (fetchInfo, successCallback, failureCallback) => {
        this.props.client.query({
            query: FETCH_FAMILY_CALENDARS_EVENTS,
            variables: {
                startDate: fetchInfo.start,
                endDate: fetchInfo.end
            }
        }).then(results => {
            const calendars = results.data.currentFamily.calendars;
            const mappedEvents = calendars[0].events.map(e => {
                return {
                    title: e.title,
                    start: moment(e.startDate).toDate(),
                    end: moment(e.endDate).toDate(),
                    allDay: e.allDay || false,
                    extendedProps: {
                        sourceEvent: e
                    }
                }
            });
            successCallback(mappedEvents);
        }).catch(error => failureCallback(error));
    }


    eventUpdated = (calendar, args) => {
        const sourceEvent = args.event.def.extendedProps.sourceEvent;
        const momentDuration = toDuration(args.delta, args.event.calendar);
        // let startDate = toMoment(args.event.instance.range.start, calendar).utc();
        // let endDate = toMoment(args.event.instance.range.end, calendar).utc();

        let startDate = moment(sourceEvent.startDate); //args.event.instance.range.start;
        let endDate = moment(sourceEvent.endDate); //args.event.instance.range.end;

        // let startDate = moment(sourceEvent.startDate);
        // let endDate = moment(sourceEvent.endDate);

        // const startDate = event.start.format()
        // const endDate = event.end ? event.end.format() : null;

        startDate = startDate.add(momentDuration).toDate();
        endDate = endDate.add(momentDuration).toDate();

        const netStart = startDate; //startDate.utc().format();
        const netEnd = endDate ? endDate : null; //endDate ? endDate.utc().format() : null;
        this.props.client.mutate({
            mutation: UPDATE_CALENDAR_EVENT,
            variables: {
                id: sourceEvent.id,
                input: {
                    startDate: netStart,
                    endDate: netEnd
                }
            }
        }).then(() => {

        }).catch((err) => {
            console.log(err);
            args.revert();
        });
    }


    eventClicked = (args) => {
        // this.props.history.push(`/calendar/event/${id}`);
    }

    render() {
        return (
            <PageTemplate>
                <NavigationBar />
                <PageTemplateContent className="calendar-home-page">
                    <Calendar
                        eventSource={this.calendarEventSource}
                        onEventUpdate={this.eventUpdated}
                        onEventClick={this.eventClicked} />

                </PageTemplateContent>
            </PageTemplate>

        )
    }
}

export const FETCH_FAMILY_CALENDARS_EVENTS = gql`
    query FetchFamilyCalendarsAndEvents($startDate: DateTime, $endDate: DateTime) {
        currentFamily {
            id
            calendars {
                id
                title 
                events(where:{
                    startDate_gt: $startDate,
                    endDate_lt: $endDate
                }) {
                    id
                    title
                    note
                    startDate
                    endDate
                }
            }
        }
    }
`;

const UPDATE_CALENDAR_EVENT = gql`
    mutation UpdateCalendarEvent($id: ID!, $input: UpdateEventInput!) {
        updateCalendarEvent(id: $id, input: $input) {
            id
            title
        }
    }
`;

export const CalendarHomePage = compose(
    withApollo
)(_CalendarHomePage);

