import classnames from 'classnames';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Label from '../../../../uikit/components/atoms/label/label';
import './dashboard-notification-card.scss';
import { ReactComponent as ChatIcon } from '../../../../uikit/internal/svg/gzm0121-chat.svg';



export class DashboardNotificationCard extends Component {

    link = (feature) => {
        switch (feature) {
            case 'messages': {
                return '/messages'
            }
            case 'calendar': {
                return '/calendar'
            }
            case 'library': {
                return '/library'
            }
            default: {
                return '/'
            }
        }
    }

    featureIcon = (feature) => {
        switch (feature) {
            case 'messages': {
                return <ChatIcon />;
            }
            case 'calendar': {
                return <i className="material-icons">calendar_today</i>;
            }
            case 'library': {
                return <i className="material-icons">bookmark</i>;
            }
            default: {
                return null;
            }
        }
    }

    featureTitle = (feature) => {
        switch (feature) {
            case 'messages': {
                return 'Messages';
            }
            case 'calendar': {
                return 'Calendar';
            }
            case 'library': {
                return 'Library';
            }
            default: {
                return null;
            }
        }
    }

    notificationText = (type, count) => {
        switch (type) {
            case 'message': {
                if (count === 1) {
                    return `${count} New Message`
                } else {
                    return `${count} New Messages`
                }
            }
            case 'calendarEvent': {
                if (count === 1) {
                    return `${count} New Calendar Event`
                } else {
                    return `${count} New Calendar Events`
                }
            }
            default: {
                return null;
            }
        }
    }
    render() {
        const { feature, type, count } = this.props;
        return (
            <Link to={this.link(feature)}>
                <div className={classnames(
                    'mdc-card dashboard-notification-card',
                    `dashboard-notification-card--feature-${feature}`
                )}>
                    <div className="dashboard-notification-card__feature-graphic">
                        {this.featureIcon(feature)}
                    </div>
                    <div className="dashboard-notification-card__feature-text">
                        <Label variant="caption" className="dashboard-notification-card__feature-title">
                            {this.featureTitle(feature)}
                        </Label>
                        <Label className="dashboard-notification-card__feature-description">
                            {this.notificationText(type, count)}
                        </Label>
                    </div>
                </div>
            </Link>
        )
    }
}

export default DashboardNotificationCard
