import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import './date-field.scss';
import { TextField } from '../text-field/text-field';


export class DateField extends Component {
    handleChange = (value) => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(this.props.name, value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(this.props.name, true);
    };


    render() {
        const { label, placeholder, className,
            onChange, onBlur, // Unused by component, used by formik
            ...rest } = this.props;

        return (
            <div className={classnames(
                'ui-date-field',
                className,
            )}>
                <DatePicker
                    dateFormat="LLL"
                    className="date-field__input"
                    customInput={<TextField />}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    showTimeSelect={true}
                    {...rest}
                />
            </div>
        )
    }
}
