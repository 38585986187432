import gql from 'graphql-tag';


export const contactFragment = gql`
    fragment contactFragment on Contact {
        id
        firstName
        lastName
        phoneNumber
        address {
            streetName
            streetNumber
        }
        note
        createdAt
    }
`;