import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Creators } from '../../state/actionTypes';
import { LibraryHomePage } from './pages/library-home-page/library-home-page';
import { ContactsListPage } from './pages/contacts-list-page/contacts-list-page';
import { ContactsCreatePage } from './pages/contacts-create-page/contacts-create-page';
import { ContactsDetailPage } from './pages/contacts-detail-page/contacts-detail-page';
import { DocumentsHomePage } from './pages/documents-home-page/documents-home-page';
import { ContactsEditPage } from './pages/contacts-edit-page/contacts-edit-page';
import { DocumentsUploadPage } from './pages/documents-upload-page/documents-upload-page';
import { DocumentsDetailPage } from './pages/documents-detail-page/documents-detail-page';


class _LibraryRoutes extends React.Component {

    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.url}/`} exact component={LibraryHomePage} />

                {/* Documents Routes */}
                <Route path={`${match.url}/documents`} exact component={DocumentsHomePage} />
                <Route path={`${match.url}/documents/upload`} exact component={DocumentsUploadPage} />
                <Route path={`${match.url}/documents/:documentId`} exact component={DocumentsDetailPage} />

                {/* Contacts routes */}
                <Route path={`${match.url}/contacts`} exact component={ContactsListPage} />
                <Route path={`${match.url}/contacts/create`} component={ContactsCreatePage} />
                <Route path={`${match.url}/contacts/:contactId/edit`} component={ContactsEditPage} />
                <Route path={`${match.url}/contacts/:contactId`} component={ContactsDetailPage} />
                {/*
                    <Route path={`${match.url}/`} exact component={ContactsHomePage} />
                    <Route path={`${match.url}/create`} component={ContactsHomePage} />
                    <Route path={`${match.url}/:cId/edit`} component={ContactsHomePage} />
                    <Route path={`${match.url}/:cId`} component={ContactsHomePage} />
                */}
            </Switch>
        )
    }
}
const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {
        openDrawer: () => {
            dispatch(Creators.openDrawer());
        },
    }
}
export const LibraryRoutes = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_LibraryRoutes);