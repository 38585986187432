import React from 'react';
import { Link } from 'react-router-dom';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import Label from '../../../../uikit/components/atoms/label/label';
import Button from '../../../../uikit/components/atoms/button/button';
import './onboarding-start-page.scss';

export class OnboardingStartPage extends React.Component {
    render() {
        return (
            <PageTemplate>

                <PageTemplateContent className="onboarding-start-page">
                    <Label variant="headline2" className="onboarding-start-page__welcome-text">
                        Welcome!
                    </Label>

                    <Label className="onboarding-start-page__ready-text">
                        Before we can get started, we need you to confirm a few things.
                    </Label>

                    <Link to="/onboarding/profile" className="onboarding-start-page__button">
                        <Button variant="primary">Okay</Button>
                    </Link>
                </PageTemplateContent>
            </PageTemplate>
        );
    }
}