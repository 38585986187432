
import env from '../config';

export const initUpload = async (file) => {
    if (file == null) {
        return alert('No file selected.');
    }
    return await getSignedRequest(file);
}
 
/*
     Function to get the temporary signed request from the app.
     If request successful, continue to upload the file using this signed
     request.
   */
const getSignedRequest = (file) => {
    return new Promise((resolve, reject) => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${env.apiUrl}/sign-s3?file-name=${file.name}&file-type=${file.type}`);
            xhr.setRequestHeader('x-token', localStorage.getItem('token'));
            xhr.setRequestHeader('x-refresh-token', localStorage.getItem('refreshToken'));
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const response = JSON.parse(xhr.responseText);
                        resolve(
                            uploadFile(file, response.signedRequest, response.url)
                        );
                    }
                    else {
                        reject('Could not get signed URL.');
                    }
                }
            };
            xhr.send();
        } catch (e) {
            console.log(e);
        }
    })
}
/*
    Function to carry out the actual PUT request to S3 using the signed request from the app.
*/
const uploadFile = (file, signedRequest, url) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', signedRequest);
        xhr.onprogress = (evt) => {
            console.log(evt);
        }
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(url);
                }
                else {
                    reject('Could not upload file.');
                }
            }
        };
        xhr.send(file);
    })
}