import gql from 'graphql-tag';
import React from 'react';
import { compose, graphql, withApollo } from 'react-apollo';
import withRouter from 'react-router-dom/withRouter';
import { ContactForm } from '../../components/contact-form/contact-form';
import { CONTACTS_LIST } from '../contacts-list-page/contacts-list-page';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import Label from '../../../../uikit/components/atoms/label/label';
import './contacts-create-page.module.scss';

export class _ContactsCreatePage extends React.Component {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        phoneNumber: '',
        note: ''
    }

    componentDidMount() {
    }

    goBack = () => {
        this.props.history.push('/library/contacts')
    }

    cancel = () => {
        this.goBack();
    }


    formCancel = () => {
        this.props.history.push('/library/contacts');
    }

    onComplete = (values) => {
        return this.props.createContact({
            options: {
                refetchQueries: [
                    CONTACTS_LIST
                ]
            },
            variables: {
                input: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNumber: values.phoneNumber,
                    // address: values.address,
                    emailAddress: values.email,
                    note: values.note,
                    isEmergencyContact: values.isEmergencyContact,
                }
            }
        }).then((res) => {
            this.props.history.push(`/library/contacts/${res.data.createContact.id}`);
        })
    }

    render() {
        return (
            <PageTemplate>
                <NavigationBar />
                <PageTemplateContent>
                    <Label variant="headline2">Create Contact</Label>
                    <ContactForm onComplete={this.onComplete} onCancel={this.formCancel} />
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}



const CREATE_CONTACT_MUTATION = gql`
    mutation CreateContact(
        $input: CreateContactInput!
    ) {
        createContact(input: $input){
            id
            firstName
            lastName
            phoneNumber
            isEmergencyContact
            emailAddress
            # address {

            # }
            note
        }
    }
`;
export const ContactsCreatePage = compose(
    withRouter,
    withApollo,
    graphql(CREATE_CONTACT_MUTATION, {
        name: 'createContact',
        options: {
            refetchQueries: ['ContactsList']
        }
    }),
)(_ContactsCreatePage);