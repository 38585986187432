import React, { Component } from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { childrenType } from '../../../internal/childrenPropType';
import './select.scss';

export class Select extends Component {
    static propTypes = {
        children: childrenType,
        placeholder: PropTypes.string,
        className: PropTypes.string,
    }

    render() {
        const { placeholder, children, className: cn, ...rest } = this.props;
        return (
            <div className={classnames('ui-select', cn)}>
                <select placeholder={placeholder} {...rest}>
                    {children}
                </select>
                <div className="ui-select__arrow">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                </div>
            </div>

        )
    }
}

export default Select
