import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '../../atoms/text-field/text-field';
import { Select } from '../../atoms/select/select';
import Checkbox from '../../atoms/checkbox/checkbox';
import Label from '../../atoms/label/label';
import classnames from 'classnames';

import './form-field.scss';
import { DateField } from '../../atoms/date-field/date-field';

export class FormField extends Component {
    static propTypes = {
        variant: PropTypes.oneOf(['text-field', 'select', 'checkbox', 'date']),
        label: PropTypes.string,
        title: PropTypes.string,
        error: PropTypes.string,
        invalid: PropTypes.bool,
        className: PropTypes.string,
        outerClassName: PropTypes.string,
    }

    render() {
        const {
            variant,
            children,
            label,
            error,
            invalid,
            outerClassName: cn,
            ...rest
        } = this.props;

        const renderCompMapping = {
            'text-field': TextField,
            'select': Select,
            'checkbox': Checkbox,
            'date': DateField,
        }

        const RenderComp = renderCompMapping[variant];
        return (
            <div className={classnames('ui-form-field', cn)}>
                <Label className="ui-form-field__label" variant="caption">{label}</Label>
                <RenderComp {...rest}>{children}</RenderComp>
                {(invalid && error)
                    && <Label className="ui-form-field__error" variant="caption">{error}</Label>}
            </div>
        )
    }
}
