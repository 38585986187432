import React from 'react';
import Label from '../label/label';
import './loading-indicator.scss';

export class LoadingIndicator extends React.PureComponent {
    render() {
        return (
            <div className="ui-loading-indicator">
                <Label>{this.props.text || 'Loading...'}</Label>
            </div>
        )
    }
}