import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SubscriptionPage } from './pages/subscription-page';

class _AccountRoutes extends React.PureComponent {
    render() {
        const { match } = this.props;
        return (
            <React.Fragment>
                <Switch>
                    <Route path={`${match.url}/`} exact component={SubscriptionPage} />
                </Switch>
            </React.Fragment>
        );
    }
}
export const AccountRoutes = (_AccountRoutes);