import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import { compose } from 'recompose';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import Label from '../../../../uikit/components/atoms/label/label';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import { CURRENT_USER_QUERY } from '../../../../graphql/queries';

class _OnboardingEmailConfirmPage extends Component {
    render() {
        const { userQuery } = this.props;
        return (
            <PageTemplate>
                <PageTemplateContent>
                    <Label variant="headline2">
                        Welcome!
                    </Label>
                    {
                        userQuery.loading
                            ? <LoadingIndicator />
                            : (
                                <React.Fragment>
                                    <Label>
                                        We've sent an email to <b>{userQuery.currentUser.email}</b> for verification.
                                        <br />
                                        You must verify your email before progressing further.
                                    </Label>
                                    {/* <Label>
                                    Wrong Email?
                                </Label> */}
                                </React.Fragment>
                            )
                    }
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}
export const OnboardingEmailConfirmPage = compose(
    graphql(CURRENT_USER_QUERY, {
        name: 'userQuery'
    })
)(_OnboardingEmailConfirmPage); 

export default OnboardingEmailConfirmPage;


