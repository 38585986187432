import React, { Component } from 'react';
import classnames from 'classnames';
import './image.scss';

export class Image extends Component {
    state = {
        expanded: false
    }

    expand = () => {
        this.setState({ expanded: true });
        document.body.classList.add('noscroll');
    }

    collapse = () => {
        this.setState({ expanded: false });
        document.body.classList.remove('noscroll');
    }
    render() {
        const { src, className: cn, preview } = this.props;
        return (
            <div className={classnames(
                'ui-image',
                cn,
                {
                    'ui-image--preview': preview,
                    'ui-image--expanded': this.state.expanded
                }
            )}>
                <div className='ui-image__background' onClick={this.collapse} />
                <div className='ui-image__container'>
                    <img src={src} alt="" onClick={this.expand} />
                </div>
            </div>
        )
    }
}

export default Image
