import gql from 'graphql-tag';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import Button from '../../../../uikit/components/atoms/button/button';
import { Image } from '../../../../uikit/components/atoms/image/image';
import Label from '../../../../uikit/components/atoms/label/label';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { formatDate } from '../../../../util/formatDate';
import './documents-detail-page.scss';



const IMAGE_ENDPOINT = 'http://familymoderator.imgix.net'

class _DocumentsDetailPage extends Component {
    goBack = () => {
        this.props.history.push('/library/documents');
    }

    render() {
        const { match } = this.props;
        return (
            <PageTemplate>
                <NavigationBar />
                {/* <PageTemplateTitle title="Document Details"
                    feature="library"
                    leftButtons={<NavigationButton icon="arrow_back" onClick={this.goBack} />} /> */}
                <PageTemplateContent className="documents-detail-page">
                    <Query query={DOCUMENT_DETAIL}
                        variables={{ id: match.params.documentId }}>
                        {({ data, loading, error }) => {
                            if (loading) { return <LoadingIndicator />; }
                            console.log(data);
                            return (
                                <>
                                    <div>
                                        <Label variant="headline2">{data.document.title}</Label>
                                        <Label>Uploaded Date: {formatDate(data.document.createdAt)}</Label>
                                        <Label>Uploaded By: {data.document.user.firstName}</Label>
                                        <a href={IMAGE_ENDPOINT + data.document.localUrl + '?auto=compress&w=600'}>
                                            <Button variant="primary" className="documents-detail-page__download-button">Download</Button>
                                        </a>
                                    </div>
                                    <Image src={data.document.localUrl} className="documents-detail-page__preview-image" />
                                </>
                            )
                        }}
                    </Query>
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}


const DOCUMENT_DETAIL = gql`
    query DocumentDetail($id: ID!) {
        document(id: $id) {
            id
            title
            localUrl
            createdAt
            user {
                id
                firstName
                lastName
            }
        }
    }
`

export const DocumentsDetailPage = _DocumentsDetailPage;
