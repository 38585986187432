import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../uikit/components/atoms/button/button';
import { Card, CardContent } from '../../../../uikit/components/atoms/card/card';
import { ContainedIcon } from '../../../../uikit/components/atoms/contained-icon/contained-icon';
import Divider from '../../../../uikit/components/atoms/divider/divider';
import Label from '../../../../uikit/components/atoms/label/label';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { ReactComponent as DocumentsIcon } from '../../../../uikit/internal/svg/gzm0080-open-folder.svg';
import { ReactComponent as NotebookIcon } from '../../../../uikit/internal/svg/gzm0244-notebook.svg';
import './library-home-page.scss';

export class LibraryHomePage extends Component {

    goBack = () => {
        this.props.history.push('/contacts');
    }

    navigateTo = (dest) => {
        this.props.history.push(dest);
    }

    render() {
        return (
            <PageTemplate>
                <NavigationBar />
                <PageTemplateContent className="library-home-page">
                    <Label variant="headline2" className="library-home-page__page-title">Library</Label>
                    <Card elevation="low" className="library-home-page__card" onClick={() => this.navigateTo('/library/documents')}>
                        <CardContent>
                            <div className="library-home-page__card-title">
                                <ContainedIcon>
                                    <DocumentsIcon />
                                </ContainedIcon>

                                <Label variant="subtitle1">
                                    Documents
                                </Label>
                            </div>
                            <Label>Upload and share files with your family</Label>
                            {/* <Divider /> */}
                            {/* <div className="library-home-page__card-actions">
                                <Label variant="caption" className="library-home-page__card-action-caption">Quick Actions</Label>
                                <Link to="/library/documents/upload">
                                    <Button variant="primary" className="library-home-page__card-action">Upload</Button>
                                </Link>
                            </div> */}
                        </CardContent>
                    </Card>
                    <Card className="library-home-page__card" onClick={() => this.navigateTo('/library/contacts')}>
                        <CardContent>
                            <div className="library-home-page__card-title">
                                <ContainedIcon>
                                    <NotebookIcon />
                                </ContainedIcon>
                                <Label variant="subtitle1">Contacts</Label>
                            </div>
                            <Label>Create and share contacts with your family</Label>
                            {/* <Divider /> */}
                            {/* <div className="library-home-page__card-actions">
                                <Label variant="caption" className="library-home-page__card-action-caption">Quick Actions</Label>
                                <Link to="/library/contacts/create">
                                    <Button variant="primary" className="library-home-page__card-action">Create</Button>
                                </Link>
                                <Button variant="primary" className="library-home-page__card-action">Emergency Contacts</Button>
                            </div> */}
                        </CardContent>
                    </Card>
                </PageTemplateContent>
            </PageTemplate>
        )
    }
}

export default LibraryHomePage
