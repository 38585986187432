import React, { PureComponent } from 'react';
import './divider.scss';

export default class Divider extends PureComponent {
    render() {
        return (
            <div className="ui-divider"></div>
        )
    }
}
