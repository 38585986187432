import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
// import { LoadingIndicator } from '../components/atoms/loading-indicator/loading-indicator';
import { DashboardPage } from './dashboard/pages/dashboard-page/dashboard-page';
import { LoginPage } from './login/pages/login-page/login-page';
// import { OnboardingRoutes } from './onboarding/onboarding-routes';
import { MessagesRoutes } from './messages/messages-routes';
import { CalendarRoutes } from './calendar/calendar-routes';
// import { SettingsRoutes } from './settings/settings-routes';
// import { OverflowPage } from './overflow/overflow-page';
import { LibraryRoutes } from './library/library-routes';
import { AccountRoutes } from './account/account-routes';
import { OnboardingRoutes } from './onboarding/onboarding-routes';
import { LoadingIndicator } from '../uikit/components/atoms/loading-indicator/loading-indicator';
import { OverflowPage } from './overflow/overflow-page';
import Query from '../../node_modules/react-apollo/Query';
import { CURRENT_USER_QUERY } from '../graphql/queries';
import { SettingsHomePage } from './settings/pages/settings-home-page';
import { LandingPage } from './landing/pages/landing-page/landing-page';
import { PrivacyPolicy } from './landing/pages/privacy-policy/privacy-policy';
import { TermsOfService } from './landing/pages/terms-of-service/terms-of-service';
// import { ReportsRoutes } from './reports/reports-routes';
// import { ProfileRoutes } from './profile/profile-routes';

const _Routes = (props) => {

    const loggedInCheck = props.loggedIn;
    const loggedInAndOnboardedCheck = props.loggedIn && props.familyLoaded;
    const pathname = props.location.pathname;

    console.log(props, loggedInAndOnboardedCheck);
    const calculateRedirect = () => {
        if (!this.props.loggedIn) {
            return '/auth'
        } else if (!this.props.familyLoaded) {
            return '/onboarding';
        } else {
            return '/'
        }
    }


    return (
        props.appLoaded ?
            <Switch>
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms-of-service" component={TermsOfService} />

                {(loggedInAndOnboardedCheck && pathname === '/')
                    && <Redirect from="/" to="/inbox" />
                }
                <Route path="/" exact component={LandingPage} />

                {!loggedInCheck ?
                    <React.Fragment>
                        <Route path="/auth" component={LoginPage} />
                        {(pathname === '/auth' || pathname === '/') ? null : <Redirect to="/auth" />}
                    </React.Fragment>
                    : <Redirect from="/auth" to="/inbox" />
                }

                {!loggedInAndOnboardedCheck ?
                    <React.Fragment>
                        <Route path="/onboarding" component={OnboardingRoutes} />
                        {pathname.includes('/onboarding') ? null : <Redirect to="/onboarding" />}
                    </React.Fragment>
                    : <Redirect from="/onboarding" to="/inbox" />
                }

                <Query query={CURRENT_USER_QUERY}>
                    {({ data, loading, error }) => {
                        if (loading) {
                            return null;
                        }
                        return (
                            // data.currentUser.subscriptionStatus === 'PAID'
                            //     ? <>
                            <>
                                <Route path="/inbox" exact component={DashboardPage} />
                                <Route path="/messages" component={MessagesRoutes} />
                                <Route path="/calendar" component={CalendarRoutes} />
                                <Route path="/library" component={LibraryRoutes} />
                                <Route path="/account" component={AccountRoutes} />
                                <Route path="/settings" component={SettingsHomePage} />
                                <Route path="/more" component={OverflowPage} />
                            </>
                            // : <>
                            //     {pathname === '/account' ? null : <Redirect to="/account" />}
                            //     <Route path="/account" component={AccountRoutes} />
                            // </>
                        )
                    }}
                </Query>
                {/*<Route path="/reports" component={ReportsRoutes} />
            <Route path="/profile" component={ProfileRoutes} />
            <Route path="/settings" component={SettingsRoutes} />*/}
            </Switch>
            : <LoadingIndicator text="Loading Family Moderator" />
    )
}


const mapStateToProps = state => {
    return {
        appLoaded: state.UI.appLoaded,
        familyLoaded: state.UI.familyLoaded,
        loggedIn: state.Auth.loggedIn,
    }
}

export const Routes = withRouter(connect(
    mapStateToProps,
)(_Routes));