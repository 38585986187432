import gql from 'graphql-tag';
import React from 'react';
import { compose, graphql, Query } from 'react-apollo';
import withRouter from 'react-router-dom/withRouter';
import { ContactForm } from '../../components/contact-form/contact-form';
import { CONTACTS_LIST } from '../contacts-list-page/contacts-list-page';
import styles from './contacts-edit-page.module.scss';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import Label from '../../../../uikit/components/atoms/label/label';

export class _ContactsEditPage extends React.Component {

    componentDidMount() {
    }

    goBack = () => {
        this.props.history.push('/library/contacts')
    }

    cancel = () => {
        this.goBack();
    }

    formCancel = (contact) => {
        this.props.history.replace(`/library/contacts/${contact.id}`);
    }

    formComplete = (values) => {

        if (!values || values.length === 0) {
            return;
        }
        let input = {};

        if (values.firstName) {
            input.firstName = values.firstName;
        }

        if (values.lastName) {
            input.lastName = values.lastName;
        }

        if (values.phoneNumber) {
            input.phoneNumber = values.phoneNumber;
        }

        if (values.email) {
            input.emailAddress = values.email;
        }

        if (values.address) {
            input.address = values.address;
        }

        if (values.note) {
            input.note = values.note;
        }
        if (values.isEmergencyContact) {
            input.isEmergencyContact = values.isEmergencyContact;
        }

        return this.props.updateContact({
            options: {
                refetchQueries: [
                    CONTACTS_LIST
                ]
            },
            variables: {
                contactId: this.props.match.params.contactId,
                input
            }
        }).then((res) => {
            this.props.history.push(`/library/contacts/${res.data.updateContact.id}`);
        })
    }

    render() {
        const { match } = this.props;
        return (
            <div className={styles.container}>
                <PageTemplate>
                    <NavigationBar />
                    {/* <PageTemplateTitle dense feature="library" title="Contacts" leftButtons={<NavigationButton icon="arrow_back" onClick={this.goBack} />} /> */}
                    <PageTemplateContent className={styles.page}>
                        <Label variant="headline2">Edit Contact</Label>
                        <Query query={CONTACT_DETAIL} variables={{ contactId: match.params.contactId }}>
                            {({ data, loading, error }) => {
                                if (loading) {
                                    return (
                                        <LoadingIndicator />
                                    )
                                }
                                if (error) {
                                    return (
                                        <p>An unexpected error occured.</p>
                                    )
                                }
                                const { contact } = data;
                                return (
                                    <ContactForm contact={contact}
                                        onCancel={() => this.formCancel(contact)}
                                        onComplete={this.formComplete} />
                                )
                            }}
                        </Query>
                    </PageTemplateContent>
                </PageTemplate>
            </div>
        )
    }
}


const CONTACT_DETAIL = gql`
    query Contact($contactId: ID!) {
        contact(id: $contactId) {
            id
            firstName
            lastName
            emailAddress
            isEmergencyContact
            # address
            phoneNumber
            note
            createdAt
            updatedAt
            # history {
            #     firstName
            #     lastName
            #     emailAddress
            #     address
            #     phoneNumber
            #     createdAt
            # }
        }
    }
`;


const UPDATE_CONTACT_MUTATION = gql`
    mutation UpdateContact(
        $contactId: ID!,
        $input: UpdateContactInput!
    ) {
        updateContact(contactId: $contactId, input: $input){
            id
            firstName
            lastName
            phoneNumber
            emailAddress
            isEmergencyContact
            # address
            note
        }
    }
`;


export const ContactsEditPage = compose(
    withRouter,
    graphql(UPDATE_CONTACT_MUTATION, {
        name: 'updateContact',
        refetchQueries: ['ContactsList']
    })
)(_ContactsEditPage);