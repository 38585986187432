import { withFormik } from 'formik';
import React from 'react';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { FormField } from '../../../../uikit/components/molecules/form-field/form-field';
import Button from '../../../../uikit/components/atoms/button/button';

import './contact-form.scss';

class _ContactForm extends React.PureComponent {

    render() {
        const {
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
        } = this.props;
        return (
            <form onSubmit={handleSubmit} className="create-contact-form">
                <div className="create-contact-form__primary-info">
                    <FormField
                        variant="text-field"
                        outerClassName="create-contact-form__primary-input"
                        name="firstName"
                        label="First Name"
                        placeholder="First Name"
                        value={values.firstName}
                        invalid={errors.firstName && touched.firstName}
                        error={errors.firstName}
                        onChange={handleChange} />
                    <FormField
                        variant="text-field"
                        outerClassName="create-contact-form__primary-input"
                        name="lastName"
                        label="Last Name"
                        placeholder="Last Name"
                        value={values.lastName}
                        invalid={errors.lastName && touched.lastName}
                        error={errors.lastName}
                        onChange={handleChange} />
                </div>
                <div className="create-contact-form__aditional-info">

                    {/* <FieldSelect
                        className={styles.calendarInput}
                        label="Relation"
                        name="relation"
                        placeholder="Relation"
                    >
                        <option value="father">Father</option>
                        <option value="mother">Mother</option>
                    </FieldSelect> */}

                    <FormField
                        variant="text-field"
                        outerClassName="create-contact-form__secondary-input"
                        name="phoneNumber"
                        label="Phone Number"
                        placeholder="Phone Number"
                        value={values.phoneNumber}
                        invalid={errors.phoneNumber && touched.phoneNumber}
                        error={errors.phoneNumber}
                        onChange={handleChange} />

                    <FormField
                        variant="text-field"
                        outerClassName="create-contact-form__secondary-input"
                        name="email"
                        label="Email"
                        placeholder="Email"
                        value={values.email}
                        invalid={errors.email && touched.email}
                        error={errors.email}
                        onChange={handleChange} />

                    <FormField
                        variant="checkbox"
                        label="Emergency Contact"
                        name="isEmergencyContact"
                        defaultChecked={values.isEmergencyContact}
                        value={values.isEmergencyContact}
                        invalid={errors.isEmergencyContact && touched.isEmergencyContact}
                        error={errors.isEmergencyContact}
                        onChange={handleChange} />

                    {/* <FieldText
                        className="create-contact-form__secondary-input"
                        name="address"
                        label="Address"
                        placeholder="Address"
                        value={values.address}
                        invalid={errors.address && touched.address}
                        error={errors.address}
                        onChange={handleChange} /> */}

                    <FormField
                        variant="text-field"
                        textarea
                        outerClassName="create-contact-form__secondary-input"
                        name="note"
                        label="Note"
                        placeholder="Note"
                        value={values.note}
                        invalid={errors.note && touched.note}
                        error={errors.note}
                        onChange={handleChange} />
                </div>
                <div className="create-contact-form__actions">
                    <Button variant="primary" type="submit">{this.props.contact ? 'Update' : 'Create'}</Button>
                    <Button variant="subtle" type="button" onClick={this.props.onCancel}>Cancel</Button>
                </div>
            </form>
        )
    }
}



export const ContactForm = compose(
    withFormik({
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .required('First Name is required'),
            lastName: Yup.string()
                .required('Last Name is required'),
            email: Yup.string()
                .email('Email Address must be valid'),
            address: Yup.string(),
            phoneNumber: Yup.string(),
            note: Yup.string(),
            isEmergencyContact: Yup.boolean(),
        }),
        mapPropsToValues(props) {
            const { contact } = props;
            const c = contact;
            return {
                firstName: c ? c.firstName : '',
                lastName: c ? c.lastName : '',
                email: c ? c.emailAddress : '',
                address: c ? c.address : '',
                phoneNumber: c ? c.phoneNumber : '',
                note: c ? c.note : '',
                isEmergencyContact: c ? c.isEmergencyContact : false,
            }
        },
        handleSubmit(values, { props, setSubmitting }) {
            setSubmitting(true);
            props.onComplete(values);

        }
    }),
)(_ContactForm);