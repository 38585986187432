import React, { Component } from 'react';
import { childrenType } from '../../../internal/childrenPropType';
import './contained-icon.scss';

export class ContainedIcon extends Component {
    static propTypes = {
        children: childrenType
    }

    render() {
        const { children } = this.props;
        return (
            <div className="contained-icon">
                {children}
            </div>
        )
    }
}

export default ContainedIcon
