import gql from 'graphql-tag';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import Button from '../../../../uikit/components/atoms/button/button';
import { Card, CardContent } from '../../../../uikit/components/atoms/card/card';
import Label from '../../../../uikit/components/atoms/label/label';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import { NavigationBar } from '../../../../uikit/components/molecules/navigation-bar/navigation-bar';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import './contacts-detail-page.scss';


export class _ContactsDetailPage extends React.Component {


    goBack = () => {
        this.props.history.push('/library/contacts');
    }

    render() {
        const contactQuery = this.props.contactDetail
        return (
            <PageTemplate>
                <NavigationBar />
                {/* <PageTemplateTitle title="Contact Detail" dense feature="library"
                    leftButtons={<NavigationButton icon="arrow_back" onClick={this.goBack} />} /> */}

                {contactQuery.loading
                    ? <LoadingIndicator />
                    : <PageTemplateContent className="contacts-detail-page">
                        <Label variant="headline2" className="contacts-detail-page__contact-name">
                            {contactQuery.contact.firstName} {contactQuery.contact.lastName}
                        </Label>
                        {contactQuery.contact.isEmergencyContact ?
                            <Label className="contacts-detail-page__emergency-title" variant="caption">
                                Emergency Contact
                                    </Label>
                            : null}
                        <Card className="contacts-detail-page__card">
                            <CardContent>
                                <Label variant="headline5">
                                    Info
                                </Label>

                                <div className="contacts-detail-page__secondary-info">
                                    {contactQuery.contact.phoneNumber ?
                                        <div className="contacts-detail-page__item">
                                            <Label className="contacts-detail-page__item-title" variant="caption">
                                                Phone Number
                                            </Label>
                                            <Label className="contacts-detail-page__item-content" variant="body1">
                                                {contactQuery.contact.phoneNumber}
                                            </Label>
                                        </div>
                                        : null}
                                    {contactQuery.contact.emailAddress ?
                                        <div className="contacts-detail-page__item">
                                            <Label className="contacts-detail-page__item-title" variant="caption">
                                                Email
                                            </Label>
                                            <Label className="contacts-detail-page__item-content" variant="body1">
                                                {contactQuery.contact.emailAddress}
                                            </Label>
                                        </div>
                                        : null}
                                    {contactQuery.contact.address ?
                                        <div className="contacts-detail-page__item">
                                            <Label className="contacts-detail-page__item-title" variant="caption">
                                                Address
                                            </Label>
                                            <Label className="contacts-detail-page__item-content" variant="body1">
                                                {contactQuery.contact.address}
                                            </Label>
                                        </div>
                                        : null}
                                    {contactQuery.contact.note ?
                                        <div className="contacts-detail-page__item">
                                            <Label className="contacts-detail-page__item-title" variant="caption">
                                                Note
                                            </Label>
                                            <Label className="contacts-detail-page__item-content" variant="body1">
                                                {contactQuery.contact.note}
                                            </Label>
                                        </div>
                                        : null}
                                </div>
                            </CardContent>
                        </Card>
                        <Link to={`/library/contacts/${contactQuery.contact.id}/edit`} className="contacts-detail-page__edit-button">
                            <Button variant="subtle">Edit Contact</Button>
                        </Link>
                        {/* <Button>Call</Button>
                            <Button>Email</Button> */}
                        {/* {JSON.stringify(contactQuery.contact.history)} */}
                    </PageTemplateContent>
                }
            </PageTemplate>
        )
    }
}


const CONTACT_DETAIL = gql`
    query Contact($contactId: ID!) {
        contact(id: $contactId) {
            id
            firstName
            lastName
            emailAddress
            # address
            phoneNumber
            isEmergencyContact
            note
            createdAt
            updatedAt
        }
    }
`;


export const ContactsDetailPage = compose(
    graphql(CONTACT_DETAIL, {
        name: 'contactDetail',
        options: props => ({
            variables: {
                contactId: props.match.params.contactId
            }
        })
    }),
)(_ContactsDetailPage);