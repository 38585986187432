import gql from 'graphql-tag';


export const CURRENT_USER_QUERY = gql`
    query CurrentUser {
        currentUser {
            id
            firstName
            lastName
            email
            subscriptionStatus
            ccLast4
        }
    }
`;

export const CURRENT_USER_WITH_FAMILY_QUERY = gql`
     query CurrentUserWithFamily {
        currentUser {
            id
            firstName
            lastName
            subscriptionStatus
            onboardingStatus {
                hasConfirmedImportedProfile
            }
            family {
                id
                title
                participants {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;