import classnames from 'classnames';
import React, { PureComponent } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { childrenType } from '../../../internal/childrenPropType';
import { ReactComponent as InboxIcon } from '../../../internal/svg/gzm0100-inbox.svg';
import { ReactComponent as ChatIcon } from '../../../internal/svg/gzm0121-chat.svg';
import { ReactComponent as MoreIcon } from '../../../internal/svg/gzm0248-thumbnails.svg';
import { ReactComponent as CalendarIcon } from '../../../internal/svg/gzm0397-calendar.svg';
import { ReactComponent as BooksIcon } from '../../../internal/svg/gzm0479-books.svg';
import Label from '../../atoms/label/label';
import './navigation-bar.scss';

class _NavigationBar extends PureComponent {
    static propTypes = {
        children: childrenType,
    }

    featureClass = (match) => {
        const className = classnames(
            'ui-navigation-bar__feature',
            {
                'selected': match
            }
        );
        return className;
    }

    matches = (feature) => {
        return this.props.location.pathname.includes(`${feature}`);
    }

    goto = (feature) => {
        this.props.history.push(feature);
    }

    render() {
        const matchMessages = this.matches('/messages');
        const matchCalendar = this.matches('/calendar');
        const matchLibrary = this.matches('/library');
        // const matchReports = this.matches('/reports');
        const matchSettings = this.matches('/settings');
        const matchAccount = this.matches('/account');
        const matchOverflow = this.matches('/more');

        const matchMore = matchOverflow || matchAccount || matchSettings;

        const matchDashboard = this.matches('/inbox');
        // const matchDashboard = !matchMessages
        //     && !matchCalendar
        //     && !matchSettings
        //     && !matchLibrary
        //     && !matchAccount
        //     && !matchReports
        //     && !matchOverflow;

        return (
            <div className="ui-navigation-bar">
                <div className="ui-navigation-bar__top-row">
                    <div className="ui-navigation-bar__logo">
                        <Label variant="headline4">FM</Label>
                    </div>
                    <div className={this.featureClass(matchDashboard)}
                        onClick={() => this.goto('/inbox')}>
                        {/* <FontAwesomeIcon size="lg" icon={faEnvelope} /> */}
                        <InboxIcon />
                        <Label variant="headline5">Inbox</Label>
                    </div>
                    <div className={this.featureClass(matchMessages)}
                        onClick={() => this.goto('/messages')}>
                        {/* <FontAwesomeIcon size="lg" icon={faCommentAlt} /> */}
                        <ChatIcon />
                        <Label variant="headline5">Messages</Label>
                    </div>
                    <div className={this.featureClass(matchCalendar)}
                        onClick={() => this.goto('/calendar')}>
                        {/* <FontAwesomeIcon size="lg" icon={faCalendarAlt} /> */}
                        <CalendarIcon />
                        <Label variant="headline5">Calendar</Label>
                    </div>
                    <div className={this.featureClass(matchLibrary)}
                        onClick={() => this.goto('/library')}>
                        {/* <FontAwesomeIcon size="lg" icon={faBookmark} /> */}
                        <BooksIcon />
                        <Label variant="headline5">Library</Label>
                    </div>
                    <div className={this.featureClass(matchMore)}
                        onClick={() => this.goto('/more')}>
                        {/* <FontAwesomeIcon size="lg" icon={faEllipsisVAlt} /> */}
                        <MoreIcon />
                        <Label variant="headline5">More</Label>
                    </div>
                </div>
            </div>
        )
    }
}


export const NavigationBar = withRouter(_NavigationBar);
