import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CalendarHomePage } from './pages/calendar-home-page/calendar-home-page';
import { CalendarCreateEventPage } from './pages/calendar-create-event-page/calendar-create-event-page';
import { CalendarEventDetailPage } from './pages/event-detail-page/event-detail-page';

class _CalendarRoutes extends React.PureComponent {
    render() {
        const { match } = this.props;
        return (
            <React.Fragment>
                <Switch>
                    <Route path={`${match.url}/`} exact component={CalendarHomePage} />
                    <Route path={`${match.url}/event/create`} component={CalendarCreateEventPage} />
                    <Route path={`${match.url}/event/:id`} component={CalendarEventDetailPage} />
                </Switch>
            </React.Fragment>
        );
    }
}
export const CalendarRoutes = (_CalendarRoutes);