import React, { PureComponent } from 'react'
import './button.scss';
import classnames from 'classnames';
import { childrenType } from '../../../internal/childrenPropType';
import PropTypes from 'prop-types'

export const Button = (props) => {
    const { variant, children, className: cn, ...rest } = props
    const classNames = classnames(
        'ui-button',
        variant,
        cn);
    return (
        <button
            className={classNames}  {...rest}>
            {children}
        </button>
    )
}

Button.propTypes = {
    variant: PropTypes.oneOf(['default', 'primary', 'subtle', 'link']),
    children: childrenType,
    className: PropTypes.string,
}

Button.defaultProps = {
    variant: 'default',
    className: null,
}

export default Button;