import React from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import Label from '../../../../uikit/components/atoms/label/label';
import { LoadingIndicator } from '../../../../uikit/components/atoms/loading-indicator/loading-indicator';
import { PageTemplate, PageTemplateContent } from '../../../../uikit/components/molecules/page-template/page-template';
import { OnboardingProfileForm } from '../../components/onboarding-profile-form/onboarding-profile-form';
import styles from './onboarding-profile-page.module.scss';
import { CURRENT_USER_QUERY } from '../../../../graphql/queries';


class _OnboardingProfilePage extends React.Component {

    componentWillReceiveProps(props) {
        console.log(props);
    }

    render() {
        const { currentUserProfile } = this.props;
        return (
            <PageTemplate>
                <PageTemplateContent className={styles.page}>
                    <Label variant="headline2">
                        Personal Information
                    </Label>
                    <Label>Please confirm that the below information is correct.</Label>
                    {currentUserProfile.loading
                        ? <LoadingIndicator text="Fetching profile..." />
                        : <OnboardingProfileForm currentUser={currentUserProfile.currentUser} />
                    }
                </PageTemplateContent>
            </PageTemplate>
        );
    }
}

// https://www.youtube.com/watch?v=yNiJkjEwmpw



export const OnboardingProfilePage = compose(
    graphql(CURRENT_USER_QUERY, {
        name: 'currentUserProfile'
    }),
)(_OnboardingProfilePage);