import gql from 'graphql-tag';
import React from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { OnboardingEmailConfirmPage } from './pages/onboarding-email-confirm-page/onboarding-email-confirm-page';
import { OnboardingInvitePage } from './pages/onboarding-invite-page/onboarding-invite-page';
import { OnboardingProfilePage } from './pages/onboarding-profile-page/onboarding-profile-page';
import { OnboardingStartPage } from './pages/onboarding-start-page/onboarding-start-page';
import { LoadingIndicator } from '../../uikit/components/atoms/loading-indicator/loading-indicator';

// import styles from './calendar-routes.module.scss';

class _OnboardingRoutes extends React.Component {

    checkHasProfile(userQuery) {
        return userQuery.loading === false && userQuery.currentUser.onboardingStatus.hasConfirmedImportedProfile;
    }

    checkHasEmail(userQuery) {
        return userQuery.loading === false && userQuery.currentUser.onboardingStatus.hasConfirmedEmail;
    }
    render() {
        const { match, userQuery } = this.props;
        return userQuery.loading
            ? <LoadingIndicator />
            : <Switch>
                {!userQuery.currentUser.onboardingStatus.hasConfirmedEmail
                    ? <Route path={`${match.url}/`} exact component={OnboardingEmailConfirmPage} />
                    :
                    <React.Fragment>
                        <Route path={`${match.url}/`} exact component={OnboardingStartPage} />
                        {/* {this.checkHasProfile(userQuery) == false */}
                        {/* ?  */}
                        <Route path={`${match.url}/profile`} component={OnboardingProfilePage} />
                        {/* : <Redirect from={`${match.url}/profile`} to={`${match.url}/invite`} /> */}
                        {/* } */}
                        <Route path={`${match.url}/invite`} component={OnboardingInvitePage} />
                    </React.Fragment>
                }
                {/* If they've confirmed profile then redirect to invite */}
                {/* {this.checkHasProfile(userQuery) == false */}
                {/* ?  */}
                {/* <Route path={`${match.url}/profile`} component={OnboardingProfilePage} /> */}
                {/* : <Redirect from={`${match.url}/profile`} to={`${match.url}/invite`} /> */}
                {/* } */}


            </Switch>
    }
}

const CURRENT_USER_QUERY = gql`
    query CurrentUser {
        currentUser {
            id
            firstName
            lastName
            onboardingStatus {
                hasConfirmedImportedProfile
                hasConfirmedEmail
            }
        }
    }
`;
const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {}
}
export const OnboardingRoutes = compose(
    graphql(CURRENT_USER_QUERY, {
        name: 'userQuery'
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(_OnboardingRoutes);