import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { childrenType } from '../../../internal/childrenPropType';
import './card.scss';

export const Card = (props) => {
    const { children, className, variant, ...rest } = props
    const classNames = classnames(
        'ui-card',
        `ui-card__${variant}`,
        className);
    return (
        <div className={classNames} {...rest}>
            {children}
        </div>
    )
}
Card.propTypes = {
    children: childrenType,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['raised', 'subtle']),
}

Card.defaultProps = {
    variant: 'raised',
}

export const CardContent = (props) => {
    const { children, className } = props
    const classNames = classnames(
        'ui-card-content',
        className);
    return (
        <div className={classNames}>
            {children}
        </div>
    )
}
CardContent.propTypes = {
    children: childrenType,
    className: PropTypes.string,
}

