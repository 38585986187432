import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { split, ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import env from '../config';

export const createClient = (errCallback) => {

    const token = localStorage.getItem('token') || null;
    const params = {};
    if (token) {
        params.authToken = token;
        params.token = localStorage.getItem("token") || null;
        params.refreshToken = localStorage.getItem("refreshToken");
    }
    const wsLink = new WebSocketLink({
        // uri: `${env.subUrl}/subscriptions`, // `ws://localhost:5000/subscriptions`,
        uri: `${env.subUrl}/subscriptions`,
        options: {
            reconnect: true,
            connectionParams: params
        }
    });

    // Create an http link:
    const httpLink = new HttpLink({
        uri: env.apiUrl + '/graphql',
    });


    const authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => {
            const authHeaders = {
                ...headers,
                "x-token": localStorage.getItem("token") || null,
                "x-refresh-token": localStorage.getItem("refreshToken"),
            }
            // if (token) {
            //     authHeaders['x-access-token'] = token;
            // }
            return {
                headers: authHeaders
            }
        });

        return forward(operation);
    });


    const afterwareLink = new ApolloLink((operation, forward) => {
        return forward(operation).map(response => {
            const { response: { headers } } = operation.getContext();
            if (headers) {
                const token = headers.get("x-token");
                const refreshToken = headers.get("x-refresh-token");
                if (token) {
                    localStorage.setItem("token", token);
                }
                if (refreshToken) {
                    localStorage.setItem("refreshToken", refreshToken);
                }
            }

            return response;
        });
    });

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const splitLink = split(
        // split based on operation type
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        afterwareLink.concat(httpLink),
    );
    const memoryCache = new InMemoryCache();

    const client = new ApolloClient({
        cache: memoryCache,
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError, ...rest }) => {
                if (graphQLErrors) {
                    graphQLErrors.map(({ message, locations, path }) => {
                        const errText = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                        return errCallback(errText);
                    })
                }
                if (networkError) {
                    const errText = `[Network error]: ${JSON.stringify(networkError)}`;
                    errCallback(errText)
                    console.log(errText, ' - ', rest);
                }
            }),
            authMiddleware,
            splitLink
        ]),
    });
    return client;
}