import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { childrenType } from '../../../internal/childrenPropType';
import './text-field.scss';
import classnames from 'classnames';
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM')

const masks = {
    "phone-number": ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    "date": [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/],
}

export class TextField extends Component {
    static propTypes = {
        children: childrenType,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        textarea: PropTypes.bool,
        mask: PropTypes.string,
    }



    render() {
        const { textarea, mask, className: cn, placeholder, ...rest } = this.props;
        let RenderComp = textarea ? 'textarea' : 'input';

        return mask ?
            <MaskedInput
                className={classnames('ui-text-field', cn)}
                // mask={masks['phone-number']}
                mask={masks['date']}
                pipe={autoCorrectedDatePipe}
                placeholder={placeholder}
                {...rest}
            />
            : <RenderComp
                className={classnames('ui-text-field', cn)}
                placeholder={placeholder}
                {...rest} />


        //     <RenderComp className={classnames('ui-text-field', cn)} placeholder={placeholder} {...rest} />
        // )
    }
}

export default TextField
