

export const readFilePreview = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            resolve(evt.target.result);
        }
        reader.readAsDataURL(file);
    })

}